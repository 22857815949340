import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { updateEntry } from "../../../../utils/firebase/firebase-firestore.utils";
import { FIREBASE_COLLECTION_NAMES } from "../../../../utils/firebase/firebase-firestore.utils";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import FormikButton from "../../../formik-button/formik-button.component";
import FormikTextfield from "../../../formik-textfield/formik-textfield.component";
import FormikDateField from "../../../formik-date/formik-date-picker.component";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import {
  EMAILER_URL,
  PAYMENT_TYPES,
  PAYMENT_TYPE_LIFETIME_ARR,
  PAYMENT_TYPE_SUBSCRIPTION_ARR,
  USER_ROLE,
} from "../../../../utils/helpers/constants";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectGroups } from "../../../../store/groups/groups.selector";
import { formatDate, formatTimestamp } from "../../../../utils/helpers/helpers";
import { selectLicenses } from "../../../../store/licenses/licenses.selector";
import { getAuth } from "firebase/auth";
import { selectConfigurations } from "../../../../store/configurations/configurations.selector";
import { selectUsers } from "../../../../store/users/users.selector";
import FormikSelect from "../../../formik-select/select.component";

const LicensePaymentEditDialog = ({ open, handleClose, payment }) => {
  const { enqueueSnackbar } = useSnackbar();
  const allGroups = useSelector(selectGroups);
  const allLicenses = useSelector(selectLicenses);
  const allConfiguration = useSelector(selectConfigurations);
  const users = useSelector(selectUsers);
  const adminEmails = users
    .filter((user) => user.role === USER_ROLE.ADMIN && user.enableNotification === true)
    .map((user) => user.notificationEmail);

  const handleSubmit = async (values, { resetForm }) => {
    const auth = getAuth();
    const licensePayedAt = dayjs(values.payedAt).toDate();
    const licensePayedTill = dayjs(values.payedTill).toDate();
    const currentLicense = allLicenses.find(
      (license) => license.licenseKey === payment.licenseKey
    );

    try {
      await updateEntry(FIREBASE_COLLECTION_NAMES.PAYMENTS, payment.id, {
        ...values,
        payedAt: licensePayedAt,
        payedTill: licensePayedTill,
        comment: values.comment.trim(),
      });

      // Emailer start
      const selectedConfigId = allConfiguration.find(
        (config) => config.id === currentLicense.configurationId
      ).groupId;
      const selectedGroup = allGroups.find(
        (group) => group.id === selectedConfigId
      );
      const usersGroupEmails = selectedGroup.userEmails;
      const usersEmails = users.filter(user => usersGroupEmails.includes(user.email) && user.enableNotification === true).map(item => item.notificationEmail)
      const formattedDate = formatTimestamp({
        seconds: new Date().getTime() / 1000,
        nanoseconds: 0,
      });

      const subject = `"${selectedGroup.groupName}" "${currentLicense.licenseName}" Payment Edited`;
      const text =
        `A payment for the license "${currentLicense.licenseName}" was edited by ${auth.currentUser.email} at ${formattedDate}.\n\n ` +
        `License Name: ${currentLicense.licenseName}\n ` +
        `License Key: ${currentLicense.licenseKey}\n ` +
        `Payment Type: ${values.paymentType}\n ` +
        `Currency: ${values.currency}\n ` +
        `Amount: ${values.amount}\n ` +
        `Payed At: ${formatDate(licensePayedAt)}\n ` +
        `Payed Till: ${
          values.paymentType === PAYMENT_TYPES.LIFETIME
            ? "N/A"
            : formatDate(licensePayedTill)
        }\n ` +
        `Comment: ${values.comment.trim()}\n `;
      const recipients = [...adminEmails, ...usersEmails];

      const emailData = {
        to: recipients,
        subject,
        text,
      };

      axios
        .post(
          EMAILER_URL,
          emailData
        )
        .then((response) => {
          console.log("Email sent successfully");
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
      // Emailer end

      enqueueSnackbar(`Payment of license "${currentLicense.licenseName}" was successfully edited.`, { variant: "success" });
      handleClose();
      resetForm();
    } catch (error) {
      enqueueSnackbar(`Failed to edit the payment of the license "${currentLicense.licenseName}". Please try again.`, {
        variant: "error",
      });
      console.error("Editing payment failed:", error);
    }
  };

  const FORM_VALIDATION = Yup.object().shape({
    paymentType: Yup.string().required("Required"),
    currency: Yup.string().required("Required"),
    amount: Yup.number()
      .typeError("Amount must be a number")
      .required("Required")
      .min(0, "Amount must be positive")
      .test(
        "positive",
        "Amount must be greater than or equal to zero",
        (value) => {
          return value >= 0;
        }
      ),
    comment: Yup.string(),
  });

  const paymentTypes = PAYMENT_TYPE_LIFETIME_ARR.includes(payment.paymentType);

  return (
    <>
      {payment && (
        <Formik
          initialValues={{
            paymentType: payment.paymentType,
            payedTill: dayjs(new Date(payment.payedTill.seconds * 1000)).format(
              "YYYY-MM-DD"
            ),
            currency: payment.currency,
            amount: payment.amount,
            comment: payment.comment,
            payedAt: dayjs(new Date(payment.payedAt.seconds * 1000)).format(
              "YYYY-MM-DD"
            ),
          }}
          enableReinitialize
          validationSchema={FORM_VALIDATION}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
                <DialogTitle>Edit Payment</DialogTitle>
                <DialogContent>
                  <Stack direction="column" spacing={2} marginY={2}>
                    <Box
                      mb={2}
                      display="flex"
                      flexDirection="column"
                      gap="10px"
                    >
                      <FormikSelect
                        name="paymentType"
                        label="Payment Type"
                        fullWidth
                        options={
                          paymentTypes
                            ? PAYMENT_TYPE_LIFETIME_ARR
                            : PAYMENT_TYPE_SUBSCRIPTION_ARR
                        }
                      />
                      <FormikDateField
                        name="payedAt"
                        label="Payed At"
                        format="YYYY-MM-DD"
                      />
                      <FormikDateField
                        name="payedTill"
                        label="Paid Till"
                        format="YYYY-MM-DD"
                        disabled={
                          payment.paymentType === PAYMENT_TYPES.LIFETIME || payment.paymentType === PAYMENT_TYPES.LIFETIME_UPGRADE}
                        fullWidth
                      />
                      <FormikTextfield
                        name="currency"
                        fullWidth
                        label="Currency"
                        variant="standard"
                      />
                      <FormikTextfield
                        name="amount"
                        fullWidth
                        label="Amount"
                        variant="standard"
                      />
                      <FormikTextfield
                        name="comment"
                        fullWidth={true}
                        label="Comment"
                        variant="standard"
                        multiline
                      />
                    </Box>
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <Button sx={{ color: "red" }} onClick={handleClose}>
                    Cancel
                  </Button>
                  <FormikButton sx={{ color: "green" }} type="submit">
                    Save
                  </FormikButton>
                </DialogActions>
              </Dialog>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default LicensePaymentEditDialog;
