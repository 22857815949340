import { Fragment, useState } from 'react';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import UserList from '../../components/user-list/user-list.component';
import UserAddDialog from '../../components/user-list/user-add-dialog.component';
import FloatingActionButton from '../../components/floating-action-button/floating-action-button.component';
import UserListSearchBar from '../../components/user-list/user-list-searchbar.component';

const Users = () => {
    const [newUserDialog, setNewUserDialog] = useState(false);

    return (
        <Fragment>
            <Container>
                <Stack spacing={4} marginTop={4} marginBottom={8}>
                    <Typography variant="h5" textAlign="center">
                        Users
                    </Typography>
                    <UserListSearchBar />
                    <UserList/>           
                </Stack>                
            </Container>
            <FloatingActionButton handleClick={() => setNewUserDialog(true)}>
                <AddIcon/>
            </FloatingActionButton>
            <UserAddDialog
                open={newUserDialog}
                handleClose={() => setNewUserDialog(false)}
            />        
        </Fragment>        
    );
}

export default Users;