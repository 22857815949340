import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { deleteEntry } from "../../utils/firebase/firebase-firestore.utils";
import { FIREBASE_COLLECTION_NAMES } from "../../utils/firebase/firebase-firestore.utils";

import { selectConfigurations } from "../../store/configurations/configurations.selector";

import Paper from "@mui/material/Paper";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import ConfirmDeclineDialog from "../confirm-decline-dialog/confirm-decline-dialog.component";
import { Box } from "@mui/material";
import { selectUsers } from "../../store/users/users.selector";
import FeaturesEditDialog from "./features-edit-dialog.component";
import axios from "axios";
import { formatTimestamp } from "../../utils/helpers/helpers";
import { getAuth } from "firebase/auth";
import { COLOR_PURPLE, EMAILER_URL, USER_ROLE } from "../../utils/helpers/constants";

const FeaturesListItem = ({ feature }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const allConfigurations = useSelector(selectConfigurations);

  //disable features
  const isFeatureEnabled = allConfigurations.some((configuration) => {
    return configuration.enabledFeatureIds.includes(feature.id);
  });

  //Admin users
  const users = useSelector(selectUsers);
  const adminEmails = users
    .filter((user) => user.role === USER_ROLE.ADMIN && user.enableNotification === true)
    .map((admin) => admin.notificationEmail);

  //Controlls

  const openEditDialog = () => {
    setIsEditDialogOpen(true);
  };

  const closeEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    const auth = getAuth();

    try {
      deleteEntry(FIREBASE_COLLECTION_NAMES.FEATURES, feature.id);
      enqueueSnackbar(
        `Feature "${feature.featureName}" was successfully deleted.`,
        {
          variant: "success",
        }
      );

      // Emailer start
      const formattedDate = formatTimestamp({
        seconds: new Date().getTime() / 1000,
        nanoseconds: 0,
      });
      const subject = `"${feature.featureName}" Feature Deleted`;
      const text = `The feature "${feature.featureName}" got deleted by ${auth.currentUser.email} at ${formattedDate}.\n \n`  + 
      `Feature Name: ${feature.featureName} \n` +
      `Feature UUID: ${feature.uuid}`;
      
      const recipients = [...adminEmails];

      const emailData = {
        to: recipients,
        subject,
        text,
      };

      axios
        .post(EMAILER_URL, emailData)
        .then((response) => {
          console.log("Email sent successfully");
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
      //Emailer end

      closeDeleteDialog();
    } catch (error) {
      enqueueSnackbar(
        `Failed to delete the feature "${feature.featureName}". Please try again.`,
        { variant: "error" }
      );
      console.log("feature deletion failed: ", error);
    }
  };

  return (
    <Paper
      sx={{
        mb: 1,
        borderRadius: "16px",
        minHeight: "72px",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        width: "100%",
        "@media (max-width: 600px)": {
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "8px",
        },
      }}
      elevation={1}
    >
      <ListItem>
        {windowWidth > 520 && (
          <ListItemAvatar>
            <Avatar
              style={{
                backgroundColor: `${COLOR_PURPLE}`,
              }}
            >
              {feature.featureName[0]}
            </Avatar>
          </ListItemAvatar>
        )}
        {windowWidth > 520 ? (
          <ListItemText
            primary={feature.featureName}
            secondary={feature.uuid}
          />
        ) : (
          <ListItemText
            sx={{ display: "flex", flexDirection: "column" }}
            primary={feature.featureName}
            secondary={feature.uuid}
          />
        )}
        <Box display="flex" gap="10px">
          <IconButton
            onClick={openEditDialog}
            sx={{
              backgroundColor: "#403C8C",
              color: "white",
              ":hover": { color: "#403C8C" },
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="error"
            disabled={isFeatureEnabled}
            onClick={openDeleteDialog}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </ListItem>
      <FeaturesEditDialog
        open={isEditDialogOpen}
        handleClose={closeEditDialog}
        feature={feature}
      />
      <ConfirmDeclineDialog
        open={isDeleteDialogOpen}
        dialogTitle="Delete Feature"
        dialogText="Are you sure that you want to delete this feature? The operation cannot be undone."
        confirmText="Confirm"
        declineText="Cancel"
        handleClose={closeDeleteDialog}
        handleConfirmAction={handleDelete}
        handleDeclineAction={closeDeleteDialog}
      />
    </Paper>
  );
};

export default FeaturesListItem;
