import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { addEntry } from "../../utils/firebase/firebase-firestore.utils";
import { FIREBASE_COLLECTION_NAMES } from "../../utils/firebase/firebase-firestore.utils";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import FormikButton from "../formik-button/formik-button.component";
import FormikTextfield from "../formik-textfield/formik-textfield.component";
import { selectUsers } from "../../store/users/users.selector";
import { selectFeatures } from "../../store/features/features.selector";
import { generateUID } from "../../utils/uid";
import { formatTimestamp } from "../../utils/helpers/helpers";
import { getAuth } from "firebase/auth";
import axios from "axios";
import { EMAILER_URL, USER_ROLE } from "../../utils/helpers/constants";

const FEATURE_UUID_LENGTH = 36;

const FeaturesAddDialog = ({ open, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const allFeatures = useSelector(selectFeatures);

  //Admin users
  const users = useSelector(selectUsers);
  const adminEmails = users
    .filter((user) => user.role === USER_ROLE.ADMIN && user.enableNotification === true)
    .map((admin) => admin.notificationEmail);

  const handleSubmit = async (values, { resetForm }) => {
    const { featureName, uuid } = values;
    const id = generateUID();
    const isFormat = featureName
      .split(" ")
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ");
    const auth = getAuth();

        // Close the dialog immediately
        handleClose();

    try {
      addEntry(FIREBASE_COLLECTION_NAMES.FEATURES, id, {
        id,
        uuid,
        featureName: isFormat,
      });

      enqueueSnackbar(`Feature "${featureName}" was successfully added.`, {
        variant: "success",
      });

      // Emailer start
      const formattedDate = formatTimestamp({
        seconds: new Date().getTime() / 1000,
        nanoseconds: 0,
      });
      const subject = `"${isFormat}" Feature Added`;
      const text = 
      `The feature "${isFormat}" got added by ${auth.currentUser.email} at ${formattedDate}.\n \n` +
      `Feature Name: ${isFormat} \n` +
      `Feature UUID: ${uuid}`;
      const recipients = [...adminEmails];

      const emailData = {
        to: recipients,
        subject,
        text,
      };

      axios
        .post(
          EMAILER_URL,
          emailData
        )
        .then((response) => {
          console.log("Email sent successfully");
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
      //Emailer end

      resetForm();
    } catch (error) {
      enqueueSnackbar(
        `Failed to add the feature "${featureName}". Please try again.`,
        { variant: "error" }
      );
      console.log("adding feature failed: ", error);
    }
  };

  const isIdUnique = (uuid) => !allFeatures.some((feat) => feat.uuid === uuid);

  const FORM_VALIDATION = Yup.object().shape({
    uuid: Yup.string()
      .length(
        FEATURE_UUID_LENGTH,
        `Must be exactly ${FEATURE_UUID_LENGTH} characters long`
      )
      .test("is-uuid-unique", "Feature UUID is already used", isIdUnique)
      .required("Required"),
    featureName: Yup.string()
      .test(
        "is-feature-name-unique",
        "Already used",
        (value) => {
          if (!value) return true;
          const formattedValue = value.split(" ")
            .map((word) => {
              if (word[0] && /^[a-zA-Z]$/.test(word[0])) {
                return word[0].toUpperCase() + word.slice(1);
              }
              return word;
            })
            .join(" ");
  
          return (
            allFeatures.filter((feature) => {
              return feature.featureName.localeCompare(formattedValue) === 0;
            }).length === 0
          );
        }
      )
      .required("Required"),
  });

  return (
    <Formik
      initialValues={{ uuid: "", featureName: "" }}
      validationSchema={FORM_VALIDATION}
      onSubmit={handleSubmit}
    >
      <Form>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
          <DialogTitle>Add New Feature</DialogTitle>
          <DialogContent>
            <Stack
              direction="column"
              spacing={2}
              marginY={2}
              alignItems="start"
            >
              <FormikTextfield
                name="featureName"
                fullWidth={true}
                label="Feature Name"
                variant="standard"
              />
              <FormikTextfield
                name="uuid"
                fullWidth
                label="Feature UUID"
                variant="standard"
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button sx={{ color: "red" }} onClick={handleClose}>
              Cancel
            </Button>
            <FormikButton sx={{ color: "green" }}>Save</FormikButton>
          </DialogActions>
        </Dialog>
      </Form>
    </Formik>
  );
};

export default FeaturesAddDialog;
