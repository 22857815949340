import { useSnackbar } from "notistack";
import { sendResetPasswordEmail } from "../../utils/firebase/firebase-auth.utils";
import { Box, Button, Paper, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import FormikTextfield from "../../components/formik-textfield/formik-textfield.component";
import * as Yup from "yup";

export const ResetPasswordForm = ({ setRefresherOpen, email }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleReset = async (values, { resetForm }) => {
    const { email } = values;

    try {
      await sendResetPasswordEmail(email);
      enqueueSnackbar(`Password reset email successfully sent to "${email}".`, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(`Failed to reset the password of the user "${email}". Please try again`, { variant: "error" });
    }
  };

  return (
    <Paper elevation={2} sx={{ width: 500, padding: 4, margin: 4 }}>
      <Typography variant="h6" component="div" textAlign="center">
        Request to reset your password
      </Typography>
      <Formik
        initialValues={{ email: email || "" }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email("Invalid email address").required("Required"),
        })}
        onSubmit={handleReset}
      >
        <Form>
          <Box mt={4}>
            <FormikTextfield name="email" label="Email-Adresse" />
          </Box>
          <Box mt={1}>
            <Button type="submit" size="large">
              Reset Password
            </Button>
            <Button
              onClick={() => setRefresherOpen(false)}
              style={{ color: "red" }}
            >
              Back
            </Button>
          </Box>
        </Form>
      </Formik>
    </Paper>
  );
};
