import { useField } from 'formik';

import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';


const FormikDateField = ({ name, ...otherProps }) => {
  const [field, meta, helpers] = useField(name);

  const handleDateChange = (value) => {
    helpers.setValue(value ? dayjs(value).toDate() : '');
  };

  return (
    <DatePicker
      {...field}
      {...otherProps}
      value={field.value ? dayjs(field.value) : null}
      onChange={handleDateChange}
      slotProps={{
        textField: {
          fullWidth: true,
          error: meta.touched && Boolean(meta.error),
          helperText: meta.touched && meta.error,
        },
      }}
    />
  );
};

export default FormikDateField;