import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "./firebase.utils";

const functions = getFunctions(firebaseApp, "europe-west1");

export const addUser = async (email, password) => {
    try {
        const cloudFunction = httpsCallable(functions, 'addUser');
        const response = await cloudFunction({ email, password });
        return response.data;
    } catch (error) {
        console.log(`error occured during user creation: `, error);
    }
}

export const deleteUser = async (email) => {
    try {
        const cloudFunction = httpsCallable(functions, 'deleteUser');
        const response = await cloudFunction({ email });
        return response.data;
    } catch (error) {
        console.log(`error occured during user deletion: `, error);
    }   
}
