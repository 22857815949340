import { FEATURES_ACTION_TYPES } from "./features.types";

const INITIAL_STATE = {
    features: [],
    searchString: "",
}

export const featuresReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch(type) {
        case FEATURES_ACTION_TYPES.SET_FEATURES:
            return { ...state, features : payload };

        case FEATURES_ACTION_TYPES.SET_FEATURES_LIST_SEARCH_STRING:
        {                
            return { ...state, searchString: payload };
        }

        default:
            return state;
    }
}