// @ts-nocheck
// @ts-ignore
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { selectEnhancedUser } from "../../store/user/user.selector";

import {
  countDocs,
  FIREBASE_COLLECTION_NAMES,
  getEntry,
  updateEntry,
} from "../../utils/firebase/firebase-firestore.utils";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import FormikButton from "../formik-button/formik-button.component";
import FormikTextfield from "../formik-textfield/formik-textfield.component";
import React, { useEffect, useRef } from "react";
import FormikSelect from "../formik-select/select.component";
import { selectUsers } from "../../store/users/users.selector";
import { formatTimestamp } from "../../utils/helpers/helpers";
import { getAuth } from "firebase/auth";
import axios from "axios";
import { EMAILER_URL, USER_ROLE } from "../../utils/helpers/constants";
import { where } from "firebase/firestore";

const UserEditDialog = ({ open, handleClose, user }) => {
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector(selectEnhancedUser);
  const formikRef = useRef(null);

  //Admin users
  const users = useSelector(selectUsers);
  const adminEmails = users
    .filter((user) => user.role === USER_ROLE.ADMIN && user.enableNotification === true)
    .map((admin) => admin.notificationEmail);

  const handleSubmit = async (values, { resetForm }) => {
    const { fullName, role, enableNotification, notificationEmail } = values;

    if (
      fullName === user.displayName &&
      user.role === role &&
      user.enableNotification === enableNotification &&
      user.notificationEmail === notificationEmail
    ) {
      handleClose();
      resetForm();

      return;
    }

    // Close the dialog immediately
    handleClose();

    const auth = getAuth();
    const updatedAt = new Date();
    try {
      await updateEntry(FIREBASE_COLLECTION_NAMES.USERS, user.email, {
        displayName: fullName,
        role,
        updatedAt,
        enableNotification,
        notificationEmail,
      });

      // Emailer start
      const formattedDate = formatTimestamp({
        seconds: new Date().getTime() / 1000,
        nanoseconds: 0,
      });
      const subject = `"${fullName}" User Edited`;
      const text =
        `The user "${fullName}" got edited by ${auth.currentUser.email} at ${formattedDate}.\n \n` +
        `User Name: ${fullName} \n` +
        `Role: ${role === USER_ROLE.ADMIN ? USER_ROLE.ADMIN : USER_ROLE.EDITOR} \n` +
        `Email Address: ${user.email}`;
      const recipients = [...adminEmails];

      const emailData = {
        to: recipients,
        subject,
        text,
      };

      if (recipients.length > 0) {
        axios
          .post(EMAILER_URL, emailData)
          .then((response) => {
            console.log("Email sent successfully:", response.data);
          })
          .catch((error) => {
            console.error("Error sending email:", error.response || error.message);
          });
      } else {
        console.error("Error: No recipients defined");
      }

      //Emailer end

      resetForm();
      enqueueSnackbar(`User "${user.email}" was successfully edited.`, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(
        `Failed to edit the user "${fullName}". Please try again.`,
        {
          variant: "error",
        }
      );
      console.log("Editing user failed: ", error);
    }
  };

  useEffect(() => {
    if (!open && formikRef.current) {
      formikRef.current.resetForm();
    }
  }, [open]);

  const FORM_VALIDATION = Yup.object().shape({
    fullName: Yup.string().required("Required"),
    role: Yup.string().required("Required"),
    enableNotification: Yup.bool().required("Required"),
    notificationEmail: Yup.string()
      .email("Invalid email address")
      .when("enableNotification", {
        is: true,
        then: Yup.string()
          .required("Required")
          .test(
            "checkNotificationEmailAvailability",
            "Email address already in use",
            async function (value) {
              const emailCount = await countDocs(
                FIREBASE_COLLECTION_NAMES.USERS,
                where("notificationEmail", "==", value)
              );
              const currentUser = await getEntry(
                FIREBASE_COLLECTION_NAMES.USERS,
                user.email
              );

              if (currentUser.notificationEmail === value && emailCount === 1) {
                return true;
              } else {
                return emailCount === 0;
              }
            }
          ),
        otherwise: Yup.string().notRequired(),
      }),
  });

  return (
    <Formik
      initialValues={{
        fullName: user.displayName,
        role: user.role,
        enableNotification: user.enableNotification,
        notificationEmail: user.notificationEmail,
      }}
      validationSchema={FORM_VALIDATION}
      onSubmit={handleSubmit}
      innerRef={formikRef}
      enableReinitialize={true}
    >
      {({ resetForm, values, setFieldValue }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (!values.enableNotification) {
            setFieldValue("notificationEmail", "");
          }
        }, [values.enableNotification, setFieldValue]);

        return (
          <Form>
            <Dialog
              open={open}
              onClose={() => {
                handleClose();
                resetForm();
              }}
              fullWidth
              maxWidth="xs"
            >
              <DialogTitle>Edit User</DialogTitle>
              <DialogContent>
                <Stack spacing={2} marginY={2} alignItems="center">
                  <FormikTextfield
                    name="fullName"
                    fullWidth
                    label="Full Name"
                    variant="standard"
                  />
                  <FormikSelect
                    name="role"
                    label="Role"
                    options={[
                      { value: USER_ROLE.ADMIN, label: USER_ROLE.ADMIN},
                      { value: USER_ROLE.EDITOR, label: USER_ROLE.EDITOR},
                      { value: USER_ROLE.VIEWER, label: USER_ROLE.VIEWER}
                    ]}
                    disabled={currentUser.email === user.email}
                  />
                  <FormikSelect
                    name="enableNotification"
                    label="Notification"
                    options={[
                      { value: false, label: "Disabled" },
                      { value: true, label: "Enabled" },
                    ]}
                  />
                  <FormikTextfield
                    name="notificationEmail"
                    fullWidth={true}
                    label="Notification Email Address"
                    variant="standard"
                    disabled={!values.enableNotification}
                  />
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    handleClose();
                    resetForm();
                  }}
                  sx={{ color: "red" }}
                >
                  Cancel
                </Button>
                <FormikButton type="submit" sx={{ color: "green" }}>
                  Save
                </FormikButton>
              </DialogActions>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserEditDialog;
