import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

import { onAuthStateChangedListener } from './utils/firebase/firebase-auth.utils';
import { getEntry, getCollection, onCollectionChangedListener } from './utils/firebase/firebase-firestore.utils';
import { FIREBASE_COLLECTION_NAMES } from './utils/firebase/firebase-firestore.utils';

import { setSignedOut, setSignInStarted, setSignInFinished } from './store/user/user.action';
import { setUsers } from './store/users/users.action';
import { setApps } from './store/apps/apps.action';
import { setLanguages } from './store/languages/languages.action';
import { setGroups } from './store/groups/groups.action';
import { setConfigurations } from './store/configurations/configurations.action';
import { setLicenses } from './store/licenses/licenses.action';

import { selectAuthStatus, selectAuthUser } from './store/user/user.selector';

import { USER_AUTH_STATES } from './store/user/user.types';

import AuthRouteAdmin from './utils/auth-routes/auth-route-admin.component';
import AuthRouteUser from './utils/auth-routes/auth-route-user.component';
import Navigation from './pages/navigation/navigation.component';
import Authentication from './pages/authentication/authentication.component';
import LicensesOverview from './pages/licenses-overview/licenses-overview.component';
import Configurations from './pages/configurations/configurations.component';
import Groups from './pages/groups/groups.component';
import Apps from './pages/apps/apps.component';
import Users from './pages/users/users.component';
import Languages from './pages/languages/languages.component';
import LoadingSpinner from './components/loading/loading-spinner.component';
import { setPayments } from './store/payments/payments.action';
import Features from './pages/features/features.component';
import { setFeatures } from './store/features/features.action';

function App() {
  const dispatch = useDispatch();
  const authStatus = useSelector(selectAuthStatus);
  const authUser = useSelector(selectAuthUser);

  // Auth User
  useEffect(() => {
    const unsub = onAuthStateChangedListener(user => {
      if(user) 
        dispatch(setSignInStarted(user));
      else
        dispatch(setSignedOut());
    });
    return () => unsub();
  }, []);

  // Enhanced User
  useEffect(() => {
    if(authStatus === USER_AUTH_STATES.SIGNED_IN_STARTED || authStatus === USER_AUTH_STATES.SIGNED_IN_FINISHED) {
      const getCurrentUser = async () => {
        const currentUser = await getEntry(FIREBASE_COLLECTION_NAMES.USERS, authUser.email);
        dispatch(setSignInFinished(currentUser));
      }
      getCurrentUser();
      const unsub = onCollectionChangedListener(FIREBASE_COLLECTION_NAMES.USERS, () => getCurrentUser());
      return () => unsub();
    }                
    
  }, [authUser, authStatus]);

  // Users
  useEffect(() => {
    if(authStatus === USER_AUTH_STATES.SIGNED_IN_STARTED || authStatus === USER_AUTH_STATES.SIGNED_IN_FINISHED) {
      const getUsers = async () => {
        const users = await getCollection(FIREBASE_COLLECTION_NAMES.USERS);
        dispatch(setUsers(users));
      };
      getUsers();
      const unsub = onCollectionChangedListener(FIREBASE_COLLECTION_NAMES.USERS, () => getUsers());
      return () => unsub();
    }
  }, [authStatus]);

  //Payments
  useEffect(() => {
    if(authStatus === USER_AUTH_STATES.SIGNED_IN_STARTED || authStatus === USER_AUTH_STATES.SIGNED_IN_FINISHED) {
      const getPayments = async () => {
        const payments = await getCollection(FIREBASE_COLLECTION_NAMES.PAYMENTS);
        dispatch(setPayments(payments));
      };
      getPayments();
      const unsub = onCollectionChangedListener(FIREBASE_COLLECTION_NAMES.PAYMENTS, () => getPayments());
      return () => unsub();
    }
  }, [authStatus])

  //Features
  useEffect(() => {
    if(authStatus === USER_AUTH_STATES.SIGNED_IN_STARTED || authStatus === USER_AUTH_STATES.SIGNED_IN_FINISHED) {
      const getFeatures = async () => {
        const features = await getCollection(FIREBASE_COLLECTION_NAMES.FEATURES);
        dispatch(setFeatures(features));
      };
      getFeatures();
      const unsub = onCollectionChangedListener(FIREBASE_COLLECTION_NAMES.FEATURES, () => getFeatures());
      return () => unsub();
    }
  }, [authStatus])

  // Apps
  useEffect(() => {
    if(authStatus === USER_AUTH_STATES.SIGNED_IN_STARTED || authStatus === USER_AUTH_STATES.SIGNED_IN_FINISHED) {
      const getApps = async () => {
          const apps = await getCollection(FIREBASE_COLLECTION_NAMES.APPS);
          dispatch(setApps(apps));
      };
      getApps();
      const unsub = onCollectionChangedListener(FIREBASE_COLLECTION_NAMES.APPS, () => getApps());
      return () => unsub();
    }
  }, [authStatus]);

  // Languages
  useEffect(() => {
    if(authStatus === USER_AUTH_STATES.SIGNED_IN_STARTED || authStatus === USER_AUTH_STATES.SIGNED_IN_FINISHED) {
      const getLanguages = async () => {
          const languages = await getCollection(FIREBASE_COLLECTION_NAMES.LANGUAGES);
          dispatch(setLanguages(languages));
      };
      getLanguages();
      const unsub = onCollectionChangedListener(FIREBASE_COLLECTION_NAMES.LANGUAGES, () => getLanguages());
      return () => unsub();
    }
  }, [authStatus]);

  // Groups
  useEffect(() => {
    if(authStatus === USER_AUTH_STATES.SIGNED_IN_STARTED || authStatus === USER_AUTH_STATES.SIGNED_IN_FINISHED) {
      const getGroups = async () => {
          const groups = await getCollection(FIREBASE_COLLECTION_NAMES.GROUPS);
          dispatch(setGroups(groups));
      };
      getGroups();
      const unsub = onCollectionChangedListener(FIREBASE_COLLECTION_NAMES.GROUPS, () => getGroups());
      return () => unsub();
    }
  }, [authStatus]);

  // Configurations
  useEffect(() => {
    if(authStatus === USER_AUTH_STATES.SIGNED_IN_STARTED || authStatus === USER_AUTH_STATES.SIGNED_IN_FINISHED) {
      const getConfigurations = async () => {
          const configurations = await getCollection(FIREBASE_COLLECTION_NAMES.CONFIGURATIONS);
          dispatch(setConfigurations(configurations));
      };
      getConfigurations();
      const unsub = onCollectionChangedListener(FIREBASE_COLLECTION_NAMES.CONFIGURATIONS, () => getConfigurations());
      return () => unsub();
    }
  }, [authStatus]);

  // Licenses
  useEffect(() => {
    if(authStatus === USER_AUTH_STATES.SIGNED_IN_STARTED || authStatus === USER_AUTH_STATES.SIGNED_IN_FINISHED) {
      const getLicenses = async () => {
          const licenses = await getCollection(FIREBASE_COLLECTION_NAMES.LICENSES);
          dispatch(setLicenses(licenses));
      };
      getLicenses();
      const unsub = onCollectionChangedListener(FIREBASE_COLLECTION_NAMES.LICENSES, () => getLicenses());
      return () => unsub();
    }
  }, [authStatus]);

  return (
    authStatus !== USER_AUTH_STATES.UNKNOWN ? (
      <Routes>
        <Route path="/" element={<Navigation/>}>
          <Route index element={<Authentication/>}/> 
          <Route path="user/" element={<AuthRouteUser/>}>
            <Route index path="licenses" element={<LicensesOverview/>}/>   
            <Route path="configurations" element={<Configurations/>}/>               
          </Route>   
          <Route path="admin/" element={<AuthRouteAdmin/>}>
            <Route index path="apps" element={<Apps/>}/>
            <Route path="languages" element={<Languages/>}/>
            <Route path="features" element={<Features />}/>      
            <Route path="groups" element={<Groups/>}/>   
            <Route path="users" element={<Users/>}/>      
          </Route>     
        </Route>
      </Routes>
    ) : (
      <LoadingSpinner/>
    )  
  );
}

export default App;
