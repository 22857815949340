// @ts-nocheck
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { updateEntry } from "../../utils/firebase/firebase-firestore.utils";
import { FIREBASE_COLLECTION_NAMES } from "../../utils/firebase/firebase-firestore.utils";

import { selectUsers } from "../../store/users/users.selector";
import { selectGroups } from "../../store/groups/groups.selector";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import FormikButton from "../formik-button/formik-button.component";
import FormikTextfield from "../formik-textfield/formik-textfield.component";
import FormikAutocomplete from "../formik-autocomplete/formik-autocomplete.component";
import React, { useEffect } from "react";
import { useRef } from "react";
import axios from "axios";
import { formatTimestamp } from "../../utils/helpers/helpers";
import { getAuth } from "firebase/auth";
import { EMAILER_URL, USER_ROLE } from "../../utils/helpers/constants";

const GroupEditDialog = ({ open, handleClose, group }) => {
  const { enqueueSnackbar } = useSnackbar();
  const formikRef = useRef(null);

  //Groups
  const allGroups = useSelector(selectGroups);

  // Users
  const allUsers = useSelector(selectUsers);
  const allUserEmails = allUsers.reduce((acc, user) => {
    acc.push(user.email);
    return acc;
  }, []);
  const initialUserEmails = allUserEmails.reduce((acc, userEmail) => {
    if (group.userEmails.includes(userEmail)) {
      acc.push(userEmail);
    }
    return acc;
  }, []).sort((a, b) => a.localeCompare(b));

  //Admin users
  const adminEmails = allUsers
    .filter(
      (user) =>
        user.role === USER_ROLE.ADMIN && user.enableNotification === true
    )
    .map((admin) => admin.notificationEmail);

  const handleSubmit = async (values, { resetForm }) => {
    const { groupName, userEmails } = values;

    if (
      group.groupName === groupName &&
      group.userEmails.length === userEmails.length &&
      group.userEmails.every((email) => userEmails.includes(email))
    ) {
      handleClose();
      resetForm();

      return;
    }

    // Close the dialog immediately
    handleClose();

    const updatedAt = new Date();
    const auth = getAuth();

    try {
      updateEntry(FIREBASE_COLLECTION_NAMES.GROUPS, group.id, {
        groupName,
        userEmails,
        updatedAt,
      });

      enqueueSnackbar(`Group "${groupName}" was successfully edited.`, {
        variant: "success",
      });

      // Emailer start
      const formattedDate = formatTimestamp({
        seconds: new Date().getTime() / 1000,
        nanoseconds: 0,
      });
      const subject = `"${groupName}" Group Edited`;
      const text =
        `The group "${groupName}" got edited by ${auth.currentUser.email} at ${formattedDate}.\n \n` +
        `Group Name: ${groupName} \n` +
        `Assigned Users: ${userEmails.join(", ")}`;
      const recipients = [...adminEmails];

      const emailData = {
        to: recipients,
        subject,
        text,
      };
      if (recipients.length > 0) {
        axios
          .post(EMAILER_URL, emailData)
          .then((response) => {
            console.log("Email sent successfully");
          })
          .catch((error) => {
            console.error("Error sending email:", error);
          });
      }

      //Emailer end

      resetForm();
    } catch (error) {
      enqueueSnackbar(
        `Failed to edit the group "${groupName}". Please try again.`,
        { variant: "error" }
      );
      console.log("editing group failed: ", error);
    }
  };

  useEffect(() => {
    if (!open && formikRef.current) {
      formikRef.current.resetForm();
    }
  }, [open]);

  const FORM_VALIDATION = Yup.object().shape({
    groupName: Yup.string()
      .test("is-group-name-unique", "Already used", (value) => {
        return (
          allGroups.filter(
            (existingGroup) =>
              existingGroup.groupName === value && existingGroup.id !== group.id
          ).length === 0
        );
      })
      .required("Required"),
    configurationNames: Yup.array().of(Yup.string()),
    userEmails: Yup.array()
      .min(1, "Required")
      .of(Yup.string().email("Invalid email")),
  });

  return (
    <Formik
      initialValues={{
        groupName: group.groupName,
        userEmails: initialUserEmails,
      }}
      validationSchema={FORM_VALIDATION}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      <Form>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
          <DialogTitle>Edit Group</DialogTitle>
          <DialogContent>
            <Stack
              direction="column"
              spacing={2}
              marginY={2}
              alignItems="start"
            >
              <FormikTextfield
                name="groupName"
                fullWidth
                label="Group Name"
                variant="standard"
              />
              <FormikAutocomplete
                name="userEmails"
                label="Assigned Users"
                options={allUserEmails}
                multiple={true}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} sx={{ color: "red" }}>
              Cancel
            </Button>
            <FormikButton sx={{ color: "green" }}>Save</FormikButton>
          </DialogActions>
        </Dialog>
      </Form>
    </Formik>
  );
};

export default GroupEditDialog;
