import { SnackbarProvider } from "notistack";

const ToastProvider = ({ children }) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default ToastProvider;
