import { combineReducers } from 'redux';

import { usersReducer } from './users/users.reducer';
import { userReducer } from './user/user.reducer';
import { appsReducer } from './apps/apps.reducer';
import { languagesReducer } from './languages/languages.reducer';
import { groupsReducer } from './groups/groups.reducer';
import { configurationsReducer } from './configurations/configurations.reducer';

import { licensesReducer } from './licenses/licenses.reducer';
import { paymentsReducer } from './payments/payments.reducer';
import { featuresReducer } from './features/features.reducer';
import { licenseOverviewReducer } from './license-overview/license-overview.reducer';

export const rootReducer = combineReducers({
  users: usersReducer,
  user: userReducer,
  apps: appsReducer,
  languages: languagesReducer,
  groups: groupsReducer,
  configurations: configurationsReducer,
  licenseOverview: licenseOverviewReducer,
  licenses: licensesReducer,
  payments: paymentsReducer,
  features: featuresReducer,
});