import { useSelector } from "react-redux";

import {
  selectLanguages,
  selectLanguagesListSearchString,
} from "../../store/languages/languages.selector";

import List from "@mui/material/List";

import LanguageListItem from "./language-list-item.component";
import useDebounce, { alphanumericSort } from "../../utils/helpers/helpers";
import { Typography } from "@mui/material";
import { warningNoLanguagesResults } from "../../utils/helpers/warnings";

const LanguageList = () => {
  const languages = useSelector(selectLanguages);

  const queryLanguages = useSelector(selectLanguagesListSearchString);
  const debounceQuery = useDebounce(queryLanguages, 500);

  const visibleLanguages = languages.filter(
    (lang) =>
      lang.languageName.toLowerCase().includes(debounceQuery.toLowerCase()) ||
      lang.languageCode.toLowerCase().includes(debounceQuery.toLowerCase())
  )
  .sort((a, b) => alphanumericSort(a, b, 'languageName'));

  return (
    <List>
      {visibleLanguages.length > 0 ? (
        visibleLanguages.map((language, idx) => (
          <LanguageListItem key={idx} language={language} />
        ))
      ) : (
        <Typography textAlign="center">{warningNoLanguagesResults}</Typography>
      )}
    </List>
  );
};

export default LanguageList;
