import { Fragment, useState } from "react";
import { useSelector } from "react-redux";

import {
  selectGroups,
  selectGroupsListSearchString,
} from "../../store/groups/groups.selector";

import Container from "@mui/material/Container";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import AddIcon from "@mui/icons-material/Add";

import GroupListItem from "../../components/group-list/group-list-item.component";
import GroupAddDialog from "../../components/group-list/group-add-dialog.component";
import FloatingActionButton from "../../components/floating-action-button/floating-action-button.component";
import React from "react";
import useDebounce, { alphanumericSort } from "../../utils/helpers/helpers";
import GroupListSearchBar from "../../components/group-list/group-list-searchbar.component";
import {
  warningNoGroup,
  warningNoGroupResults,
} from "../../utils/helpers/warnings";

const Groups = () => {
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const groups = useSelector(selectGroups);

  const queryLanguages = useSelector(selectGroupsListSearchString);
  const debounceQuery = useDebounce(queryLanguages, 500);

  const visibleGroups = groups.filter((group) => {
    const query = debounceQuery.toLowerCase();
    const groupNameMatch = group.groupName.toLowerCase().includes(query);
    const userEmailsMatch = group.userEmails.some(email => email.toLowerCase().includes(query));
    
    return groupNameMatch || userEmailsMatch;
  })
  .sort((a, b) => alphanumericSort(a, b, 'groupName'));
  
  const determineWarning = () => {
    if (groups.length === 0) {
      return warningNoGroup;
    } else if (visibleGroups.length === 0) {
      return warningNoGroupResults;
    }

    return null;
  };

  const warningMessage = determineWarning();

  const openAddDialog = () => {
    setIsAddDialogOpen(true);
  };

  const closeAddDialog = () => {
    setIsAddDialogOpen(false);
  };

  return (
    <Fragment>
      <Container>
        <Stack spacing={4} marginTop={4} marginBottom={8}>
          <Typography variant="h5" textAlign="center">
            Groups
          </Typography>
          <GroupListSearchBar />
          <List>
            {!warningMessage ? visibleGroups.map((group, idx) => (
              <GroupListItem key={idx} group={group} />
            )) : <Typography textAlign="center">{warningMessage}</Typography>}
          </List>
        </Stack>
      </Container>
      <FloatingActionButton handleClick={openAddDialog}>
        <AddIcon />
      </FloatingActionButton>
      <GroupAddDialog open={isAddDialogOpen} handleClose={closeAddDialog} />
    </Fragment>
  );
};

export default Groups;
