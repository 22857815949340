import { useSelector } from "react-redux";

import {
  selectUsers,
  selectUsersListSearchString,
} from "../../store/users/users.selector";

import List from "@mui/material/List";

import UserListItem from "./user-list-item.component";
import useDebounce, { alphanumericSort } from "../../utils/helpers/helpers";
import { warningNoUsersResults } from "../../utils/helpers/warnings";
import { Typography } from "@mui/material";

const UserList = () => {
  const users = useSelector(selectUsers);

  const queryLanguages = useSelector(selectUsersListSearchString);
  const debounceQuery = useDebounce(queryLanguages, 500);

  const visibleUsers = users
    .filter(
      (user) =>
        user.displayName.toLowerCase().includes(debounceQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(debounceQuery.toLowerCase())
    )
    .sort((a, b) => alphanumericSort(a, b, "displayName"));

  return (
    <List>
      {visibleUsers.length > 0 ? (
        visibleUsers.map((user, idx) => <UserListItem key={idx} user={user} />)
      ) : (
        <Typography textAlign="center">{warningNoUsersResults}</Typography>
      )}
    </List>
  );
};

export default UserList;
