import { LICENSES_ACTION_TYPES } from "./licenses.types";

const INITIAL_STATE = {
    licenses: [],
}

export const licensesReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch(type) {
        case LICENSES_ACTION_TYPES.SET_LICENSES:
            return { ...state, licenses : payload };

        default:
            return state;
    }
}