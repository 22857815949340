import { createSelector } from "reselect";

import { selectGroups } from "../groups/groups.selector";

export const selectUser = (state) => state.user;

export const selectAuthStatus = createSelector(
    [selectUser],
    user => user.authStatus
)

export const selectAuthUser = createSelector(
    [selectUser],
    user => user.authUser
)

export const selectEnhancedUser = createSelector(
    [selectUser],
    user => user.enhancedUser
)

export const selectUserGroups = createSelector(
    [selectEnhancedUser, selectGroups],
    (enhancedUser, groups) => groups.reduce((acc, group) => {
        if(group.userEmails.includes(enhancedUser.email)) {
            acc.push(group);
        }
        return acc;
    }, [])
)