import React from 'react';
import { Box, Chip, Grid, Typography } from '@mui/material';

const AddAppsSelection = ({ allAppNames, appStates, toggleApps }) => {
  return (
    <Grid item xs={12} md={6} sx={{ pt: 1.5 }}>
      <Typography variant="subtitle1" fontWeight={500}>
        Apps
      </Typography>
      <Box sx={{ mt: 1 }}>
        {allAppNames.length > 0 ? (
          allAppNames.map((app) => (
            <Chip
              onClick={() => toggleApps(app.id)}
              key={app.appName}
              label={app.appName}
              color={
                appStates[app.id] === "enabled"
                  ? "success"
                  : appStates[app.id] === "locked"
                  ? "warning"
                  : "error"
              }
              sx={{ marginRight: 1, marginBottom: 1 }}
            />
          ))
        ) : (
          <Typography variant="body2">No Apps Avaliable</Typography>
        )}
      </Box>
    </Grid>
  );
};

export default AddAppsSelection;
