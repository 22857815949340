import { LANGUAGES_ACTION_TYPES } from "./languages.types";

const INITIAL_STATE = {
  languages: [],
  searchString: "",
};

export const languagesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case LANGUAGES_ACTION_TYPES.SET_LANGUAGES:
      return { ...state, languages: payload };

    case LANGUAGES_ACTION_TYPES.SET_LANGUAGES_LIST_SEARCH_STRING: {
      return { ...state, searchString: payload };
    }

    default:
      return state;
  }
};
