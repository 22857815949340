import dayjs from 'dayjs';
import { createAction } from '../../utils/reducer/reducer';
import { PAYMENTS_ACTION_TYPES } from './payments.types';

const convertFirestoreTimestampToDate = (timestamp) => {
  return dayjs.unix(timestamp.seconds).toDate();
};

export const setPayments = (payments) => {
  payments.sort((a, b) => convertFirestoreTimestampToDate(b.createdAt) - convertFirestoreTimestampToDate(a.createdAt));
  return createAction(PAYMENTS_ACTION_TYPES.SET_PAYMENTS, payments);
};