import { FormControl} from "@mui/material";
import React from "react";
import FormikAutocomplete from "../../formik-autocomplete/formik-autocomplete.component";

export const LicenseFilterSelector = ({
  setFilterOptions,
  avalibleOptions,
  filterName,
  fieldName,
  multiple,
}) => {
  return (
      <FormControl fullWidth={true}>
        <FormikAutocomplete
          name={fieldName}
          options={
            avalibleOptions.length === 0 ? [`${filterName}`] : avalibleOptions
          }
          label={filterName}
          multiple={multiple}
          handleGroups={setFilterOptions}
        />
      </FormControl>
  );
};
