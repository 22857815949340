import { USER_ACTION_TYPES } from "./user.types";
import { createAction } from "../../utils/reducer/reducer";

export const setSignInStarted = (authUser) => {
    return createAction(USER_ACTION_TYPES.SET_SIGN_IN_STARTED, authUser);
}

export const setSignInFinished = (enhancedUser) => {
    return createAction(USER_ACTION_TYPES.SET_SIGN_IN_FINISHED, enhancedUser);
}

export const setSignedOut = () => {
    return createAction(USER_ACTION_TYPES.SET_SIGNED_OUT);
}