import { createAction } from "../../utils/reducer/reducer"
import { LICENSE_OVERVIEW_ACTION_TYPES } from "./license-overview.types"

export const setLicenseOverviewGroupId = (groupId) => {
    return createAction(LICENSE_OVERVIEW_ACTION_TYPES.SET_GROUP_ID, groupId);
}

export const setLicenseListSearchString = (searchString) => {
    return createAction(LICENSE_OVERVIEW_ACTION_TYPES.SET_LICENSE_LIST_SEARCH_STRING, searchString);
}; 

export const setLicenseListTypeSelect = (types) => {
    return createAction(LICENSE_OVERVIEW_ACTION_TYPES.SET_LICENSE_LIST_TYPE_SELECT, types);
}; 

export const setLicenseListStatusSelect = (statuses) => {
    return createAction(LICENSE_OVERVIEW_ACTION_TYPES.SET_LICENSE_LIST_STATUS_SELECT, statuses);
}; 

export const setLicenseListSortBy = (sortBy) => {
    return createAction(LICENSE_OVERVIEW_ACTION_TYPES.SET_LICENSE_LIST_SORT_BY, sortBy);
}; 