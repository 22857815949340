import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import {
  FIREBASE_COLLECTION_NAMES,
  addEntry,
} from "../../../../utils/firebase/firebase-firestore.utils";
import { Form, Formik } from "formik";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import FormikTextfield from "../../../formik-textfield/formik-textfield.component";
import FormikButton from "../../../formik-button/formik-button.component";
import * as Yup from "yup";
import FormikDateField from "../../../formik-date/formik-date-picker.component";
import dayjs from "dayjs-ext";
import { generateUID } from "../../../../utils/uid";
import { EMAILER_URL, PAYMENT_TYPES, USER_ROLE } from "../../../../utils/helpers/constants";
import { useSelector } from "react-redux";
import { selectLicenseOverviewGroupId } from "../../../../store/license-overview/license-overview.selector";
import { selectGroups } from "../../../../store/groups/groups.selector";
import { formatDate, formatTimestamp } from "../../../../utils/helpers/helpers";
import { getAuth } from "firebase/auth";
import axios from "axios";
import { selectUsers } from "../../../../store/users/users.selector";
import FormikSelect from "../../../formik-select/select.component";


const LicensePaymentDialog = ({ open, handleClose, license }) => {
  const { enqueueSnackbar } = useSnackbar();
  const formikRef = useRef();
  const selectedGroupId = useSelector(selectLicenseOverviewGroupId)
  const allGroups = useSelector(selectGroups)
  const users = useSelector(selectUsers)
  const adminEmails = users.filter(user => user.role === USER_ROLE.ADMIN && user.enableNotification === true).map(user => user.notificationEmail)


  const { type, licenseKey, licenseName } = license;

  useEffect(() => {
    if (!open && formikRef.current) {
      formikRef.current.resetForm();
    }
  }, [open]);

  const handleSubmit = async (values, { resetForm }) => {
    const { paymentType, payedTill, currency, amount, comment, payedAt } = values;
    const createdAt = new Date();
    const id = generateUID();
    const auth = getAuth()

    const upperCurrency = currency.toUpperCase()

    try {
      await addEntry(FIREBASE_COLLECTION_NAMES.PAYMENTS, id, {
        id,
        licenseKey,
        currency: upperCurrency,
        createdAt,
        payedAt: new Date(payedAt),
        amount,
        comment: comment.trim(),
        paymentType,
        payedTill,
      });

     // Emailer start
    const selectedGroup = allGroups.find(group => group.id === selectedGroupId);
    const usersGroupEmails = selectedGroup.userEmails;
    const usersEmails = users.filter(user => usersGroupEmails.includes(user.email) && user.enableNotification === true).map(item => item.notificationEmail)
    const formattedDate = formatTimestamp({
      seconds: new Date().getTime() / 1000,
      nanoseconds: 0,
    });

    const subject = `"${selectedGroup.groupName}" "${licenseName}" Payment Added`;
    const text = 
      `A payment for the license "${licenseName}" was added by ${auth.currentUser.email} at ${formattedDate}. \n\n ` +
      `License Name: ${licenseName}\n ` +
      `License Key: ${licenseKey}\n ` +
      `Payment Type: ${paymentType}\n ` +
      `Currency: ${upperCurrency}\n ` +
      `Amount: ${amount}\n ` +
      `Payed At: ${formatDate(new Date(payedAt))}\n ` +
      `Payed Till: ${paymentType === PAYMENT_TYPES.LIFETIME ? "N/A" : formatDate(payedTill)}\n ` +
      `Comment: ${comment.trim()}\n ` 
    ;

    const recipients = [...adminEmails, ...usersEmails];

    const emailData = {
      to: recipients,
      subject,
      text,
    };

    axios
      .post(EMAILER_URL, emailData)
      .then((response) => {
        console.log("Email sent successfully");
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
    // Emailer end

      enqueueSnackbar(`Payment was successfully added to license "${licenseName}".`, {
        variant: "success",
      });

      handleClose();
      resetForm();
    } catch (error) {
      enqueueSnackbar(`Failed to add the payment to the license "${licenseName}". Please try again.`, {
        variant: "error",
      });
      console.error("Payment for license failed:", error);
    }
  };

  const FORM_VALIDATION = Yup.object().shape({
    paymentType: Yup.string().required("Payment Type is required"),
    payedTill: Yup.date().when("paymentType", {
      is: (paymentType) => paymentType !== PAYMENT_TYPES.LIFETIME,
      then: Yup.date()
        .min(dayjs().toDate(), "Date has to be in the future")
        .required("Required"),
      otherwise: Yup.date().nullable(),
    }),
    currency: Yup.string()
    .matches(/^[A-Za-z]+$/, "Currency must contain only alphabetical characters")
    .required("Required"),
    amount: Yup.number()
    .typeError("Amount must be a number")
    .required("Required")
    .min(0, "Amount must be positive")
    .test(
      "positive",
      "Amount must be greater than or equal to zero",
      (value) => {
        return value >= 0;
      }
    ),
    comment: Yup.string().max(255, "Comment must be at most 255 characters"),
  });

  const initialValues = {
    paymentType: type === PAYMENT_TYPES.LIFETIME ? PAYMENT_TYPES.LIFETIME : PAYMENT_TYPES.SUBSCRIPTION,
    payedTill: dayjs().add(0, "month").toDate(),
    currency: "",
    amount: "",
    comment: "",
    payedAt: dayjs().add(0, "month"),
  };

  const paymentTypeOptions = type === PAYMENT_TYPES.LIFETIME
    ? [PAYMENT_TYPES.LIFETIME, PAYMENT_TYPES.LIFETIME_UPGRADE]
    : [PAYMENT_TYPES.SUBSCRIPTION, PAYMENT_TYPES.SUBSCRIPTION_UPGRADE, PAYMENT_TYPES.SUBSCRIPTION_EXTENSION];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={FORM_VALIDATION}
      onSubmit={handleSubmit}
      innerRef={formikRef}
      enableReinitialize={true}
    >
      <Form>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
          <DialogTitle>Payment</DialogTitle>
          <DialogContent>
            <Stack direction="column" spacing={2} marginY={2} alignItems="start">
              <Box mb={2} display="flex" flexDirection="column" gap="10px" width="100%">
                <FormikSelect
                  name="paymentType"
                  label="Payment Type"
                  fullWidth
                  options={paymentTypeOptions}
                />
                <FormikDateField
                  name="payedAt"
                  label="Payed At"
                  format="YYYY-MM-DD"
                />
                <FormikDateField
                  name="payedTill"
                  label="Paid Till"
                  format="YYYY-MM-DD"
                  fullWidth={true}
                  disabled={type === PAYMENT_TYPES.LIFETIME || type === PAYMENT_TYPES.LIFETIME_UPGRADE}
                />
                <FormikTextfield
                  name="currency"
                  fullWidth={true}
                  label="Currency"
                  variant="standard"
                />
                <FormikTextfield
                  name="amount"
                  fullWidth={true}
                  label="Amount"
                  variant="standard"
                />
                <FormikTextfield
                  name="comment"
                  fullWidth={true}
                  label="Comment"
                  variant="standard"
                  multiline
                />
              </Box>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button sx={{ color: "red" }} onClick={handleClose}>Cancel</Button>
            <FormikButton sx={{ color: "green" }} type="submit">Save</FormikButton>
          </DialogActions>
        </Dialog>
      </Form>
    </Formik>
  );
};

export default LicensePaymentDialog;
