import { PAYMENTS_ACTION_TYPES } from "./payments.types";


const INITIAL_STATE = {
    payments: [],
};

export const paymentsReducer= (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch(type) {
        case PAYMENTS_ACTION_TYPES.SET_PAYMENTS:
            return { ...state, payments: payload };
        
        default:
            return state;
    }
}