import { Outlet } from 'react-router-dom';
import Box from "@mui/material/Box";
import Header from '../../components/header/header.component';
import Footer from '../../components/footer/footer.component';

const Navigation = () => {
  return (
    <Box sx={{ display:"flex", flexDirection:"column", minHeight:"100vh" }}>
      <Header/>
      <Box sx={{ flexGrow:1, paddingTop: "64px", display: "flex", flexDirection: "column" }}>
        <Outlet sx={{ flexGrow: 1 }} />
      </Box>
      <Footer />
    </Box>
  );
}

export default Navigation;
