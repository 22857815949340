import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { updateEntry } from "../../utils/firebase/firebase-firestore.utils";
import { FIREBASE_COLLECTION_NAMES } from "../../utils/firebase/firebase-firestore.utils";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import FormikButton from "../formik-button/formik-button.component";
import FormikTextfield from "../formik-textfield/formik-textfield.component";
import { selectUsers } from "../../store/users/users.selector";
import { selectFeatures } from "../../store/features/features.selector";
import axios from "axios";
import { formatTimestamp } from "../../utils/helpers/helpers";
import { getAuth } from "firebase/auth";
import { EMAILER_URL, USER_ROLE } from "../../utils/helpers/constants";

const FeaturesEditDialog = ({ open, handleClose, feature }) => {
  const { enqueueSnackbar } = useSnackbar();

  const allFeatures = useSelector(selectFeatures);

  // Admin users
  const users = useSelector(selectUsers);
  const adminEmails = users
    .filter((user) => user.role === USER_ROLE.ADMIN && user.enableNotification === true)
    .map((admin) => admin.notificationEmail);

  const handleSubmit = async (values, { resetForm }) => {
    const { featureName, uuid } = values;

    if (featureName === feature.featureName && uuid === feature.uuid) {
      handleClose();
      resetForm();

      return;
    }

    // Close the dialog immediately
    handleClose();

    const isFormat = featureName
      .split(" ")
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ");

    const updatedAt = new Date();
    const auth = getAuth();

    try {
      await updateEntry(FIREBASE_COLLECTION_NAMES.FEATURES, feature.id, {
        id: feature.id,
        featureName: isFormat,
        uuid,
        updatedAt,
      });

      enqueueSnackbar(`Feature "${featureName}" was successfully edited.`, {
        variant: "success",
      });

      // Emailer start
      const formattedDate = formatTimestamp({
        seconds: new Date().getTime() / 1000,
        nanoseconds: 0,
      });
      const subject = `"${isFormat}" Feature Edited`;
      const text =
        `The feature "${isFormat}" got edited by ${auth.currentUser.email} at ${formattedDate}.\n \n` +
        `Feature Name: ${isFormat} \n` +
        `Feature UUID: ${uuid}`;
      const recipients = [...adminEmails];

      const emailData = {
        to: recipients,
        subject,
        text,
      };

      axios
        .post(EMAILER_URL, emailData)
        .then((response) => {
          console.log("Email sent successfully");
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
      //Emailer end

      resetForm();
    } catch (error) {
      enqueueSnackbar(
        `Failed to edit the feature "${featureName}". Please try again.`,
        { variant: "error" }
      );
      console.log("Editing feature failed: ", error);
    }
  };

  const FORM_VALIDATION = Yup.object().shape({
    featureName: Yup.string()
      .test("is-feature-name-unique", "Already used", (value) => {
        if (!value || value === feature.featureName) {
          return true; // Ignore validation if the value is the same as the current feature name
        }
        return (
          allFeatures.filter((feature) => {
            return feature.featureName.localeCompare(value) === 0;
          }).length === 0
        );
      })
      .required("Required"),
  });

  return (
    <Formik
      initialValues={{ featureName: feature.featureName, uuid: feature.uuid }}
      validationSchema={FORM_VALIDATION}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ resetForm }) => (
        <Form>
          <Dialog
            open={open}
            onClose={() => {
              resetForm();
              handleClose();
            }}
            fullWidth
            maxWidth="xs"
          >
            <DialogTitle>Edit Feature</DialogTitle>
            <DialogContent>
              <Stack
                direction="column"
                spacing={2}
                marginY={2}
                alignItems="start"
              >
                <FormikTextfield
                  name="featureName"
                  fullWidth={true}
                  label="Feature Name"
                  variant="standard"
                />
                <FormikTextfield
                  name="uuid"
                  fullWidth
                  label="Feature UUID"
                  variant="standard"
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ color: "red" }}
                onClick={() => {
                  resetForm();
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <FormikButton type="submit" sx={{ color: "green" }}>
                Save
              </FormikButton>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

export default FeaturesEditDialog;
