export const generateLicenseKey = () => {
    const length = 16;
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    for (let i = 1; i <= length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));

        // Check if it's the fourth character and not the last character of the final length
        if (i % 4 === 0 && i !== 0 && i !== length) { 
            result += '-';
        }
    }

    return result;
}