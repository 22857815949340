import { useSelector } from "react-redux";

import {
  selectAppListSearchString,
  selectApps,
} from "../../store/apps/apps.selector";

import List from "@mui/material/List";

import AppListItem from "./app-list-item.component";
import useDebounce, { alphanumericSort } from "../../utils/helpers/helpers";
import { warningNoAppsResults } from "../../utils/helpers/warnings";
import { Typography } from "@mui/material";

const AppList = () => {
  const apps = useSelector(selectApps);
  const queryApps = useSelector(selectAppListSearchString);
  const debounceQuery = useDebounce(queryApps, 500);

  const visibleApps = apps.filter(
    (app) =>
      app.appName.toLowerCase().includes(debounceQuery.toLowerCase()) ||
      app.uuid.toLowerCase().includes(debounceQuery.toLowerCase())
  ).sort((a, b) => alphanumericSort(a, b, "appName"));

  return (
    <List>
      {visibleApps.length > 0 ? (
        visibleApps.map((app, idx) => <AppListItem key={idx} app={app} />)
      ) : (
        <Typography textAlign="center">{warningNoAppsResults}</Typography>
      )}
    </List>
  );
};

export default AppList;
