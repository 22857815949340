import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { addEntry } from "../../utils/firebase/firebase-firestore.utils";
import { FIREBASE_COLLECTION_NAMES } from "../../utils/firebase/firebase-firestore.utils";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import FormikButton from "../formik-button/formik-button.component";
import FormikTextfield from "../formik-textfield/formik-textfield.component";
import { selectApps } from "../../store/apps/apps.selector";
import { selectUsers } from "../../store/users/users.selector";
import { useEffect, useRef } from "react";
import { formatTimestamp } from "../../utils/helpers/helpers";
import { getAuth } from "firebase/auth";
import axios from "axios";
import { EMAILER_URL, USER_ROLE } from "../../utils/helpers/constants";
import { generateUID } from "../../utils/uid";

const APP_UUID_LENGTH = 36;

const AppAddDialog = ({ open, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const formikRef = useRef();

  const allApps = useSelector(selectApps);
  const users = useSelector(selectUsers);

  const adminEmails = users
    .filter((user) => user.role === USER_ROLE.ADMIN && user.enableNotification === true)
    .flatMap((user) => user.notificationEmail);

  useEffect(() => {
    if (!open && formikRef.current) {
      formikRef.current.resetForm();
    }
  }, [open]);

  const handleSubmit = async (values, { resetForm }) => {
    const { appName, uuid } = values;
    const auth = getAuth();
    const id = generateUID();

        // Close the dialog immediately
        handleClose();

    try {
      await addEntry(FIREBASE_COLLECTION_NAMES.APPS, id, { id, uuid, appName });

      enqueueSnackbar(`App "${appName}" was successfully added.`, {
        variant: "success",
      });

      // Emailer start
      const formattedDate = formatTimestamp({
        seconds: new Date().getTime() / 1000,
        nanoseconds: 0,
      });
      const subject = `"${appName}" App Added`;
      const text =
        `The app "${appName}" got added by ${auth.currentUser.email} at ${formattedDate}.\n \n` +
        `App Name: ${appName} \n` +
        `App UUID: ${uuid}`;
      const recipients = [...adminEmails];

      const emailData = {
        to: recipients,
        subject,
        text,
      };

      axios
        .post(EMAILER_URL, emailData)
        .then((response) => {
          console.log("Email sent successfully");
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
      //Emailer end

      resetForm();
    } catch (error) {
      enqueueSnackbar(
        `Failed to add the app "${appName}". Please try again.`,
        { variant: "error" }
      );
      console.error("adding app failed:", error);
    }
  };

  const isIdUnique = (uuid) => !allApps.some((app) => app.uuid === uuid);
  const isAppNameUnique = (appName) =>
    !allApps.some((app) => app.appName.localeCompare(appName) === 0);

  const FORM_VALIDATION = Yup.object().shape({
    uuid: Yup.string()
      .length(
        APP_UUID_LENGTH,
        `Must be exactly ${APP_UUID_LENGTH} characters long`
      )
      .test("is-uuid-unique", "App UUID is already used", isIdUnique)
      .required("Required"),
    appName: Yup.string()
      .test("is-app-name-unique", "Already used", isAppNameUnique)
      .required("Required"),
  });

  return (
    <Formik
      initialValues={{ uuid: "", appName: "" }}
      validationSchema={FORM_VALIDATION}
      onSubmit={handleSubmit}
      innerRef={formikRef}
      enableReinitialize={true}
    >
      <Form>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
          <DialogTitle>Add New App</DialogTitle>
          <DialogContent>
            <Stack
              direction="column"
              spacing={2}
              marginY={2}
              alignItems="start"
            >
              <FormikTextfield
                name="appName"
                fullWidth
                label="App Name"
                variant="standard"
              />
              <FormikTextfield
                name="uuid"
                fullWidth
                label="App UUID"
                variant="standard"
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button sx={{ color: "red" }} onClick={handleClose}>
              Cancel
            </Button>
            <FormikButton type="submit" sx={{ color: "green" }}>
              Save
            </FormikButton>
          </DialogActions>
        </Dialog>
      </Form>
    </Formik>
  );
};

export default AppAddDialog;
