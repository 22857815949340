import { USERS_ACTION_TYPES } from "./users.types";

const INITIAL_STATE = {
  users: [],
  searchString: "",
};

export const usersReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case USERS_ACTION_TYPES.SET_USERS:
      return { ...state, users: payload };

    case USERS_ACTION_TYPES.SET_USERS_LIST_SEARCH_STRING: {
      return { ...state, searchString: payload };
    }

    default:
      return state;
  }
};
