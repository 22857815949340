import { useFormikContext } from 'formik';

import Button from '@mui/material/Button';

const FormikButton = ({ children, ...otherProps }) => {
    const { submitForm } = useFormikContext();

    const handleSubmit = () => {
        submitForm();
    }

    const configButton = {
        ...otherProps,
        onClick: handleSubmit
    }

    return (
        <Button {...configButton}>
            {children}
        </Button>
    );
}

export default FormikButton;