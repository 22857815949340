import { CONFIGURATIONS_ACTION_TYPES } from "./configurations.types";

const INITIAL_STATE = {
    configurations: [],
};

export const configurationsReducer= (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch(type) {
        case CONFIGURATIONS_ACTION_TYPES.SET_CONFIGURATIONS:
            return { ...state, configurations: payload };
        
        default:
            return state;
    }
}