// @ts-nocheck
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { updateEntry } from "../../utils/firebase/firebase-firestore.utils";
import { FIREBASE_COLLECTION_NAMES } from "../../utils/firebase/firebase-firestore.utils";

import { selectConfigurations } from "../../store/configurations/configurations.selector";
import { selectApps } from "../../store/apps/apps.selector";
import { selectLanguages } from "../../store/languages/languages.selector";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@mui/material";

import { filterAndSortApps, formatTimestamp, sortChips } from "../../utils/helpers/helpers";
import AddAppsSelection from "./configuration-list-subs/add-apps-selection";
import AddGeneralInfoForm from "./configuration-list-subs/add-general-info-form";
import AddFeaturesAndLanguages from "./configuration-list-subs/add-features-languages";
import FormikButton from "../formik-button/formik-button.component";
import { selectGroups } from "../../store/groups/groups.selector";
import { selectUsers } from "../../store/users/users.selector";
import { selectFeatures } from "../../store/features/features.selector";
import NextButton from "./configuration-list-subs/configuration-next-button";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { EMAILER_URL, USER_ROLE } from "../../utils/helpers/constants";

const ConfigurationEditDialog = ({ open, handleClose, configuration }) => {
  const { enqueueSnackbar } = useSnackbar();
  const formikRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["General", "App", "Features"];
  const maxSteps = steps.length;

  // configs
  const allConfigurations = useSelector(selectConfigurations);
  // Admin Users
  const users = useSelector(selectUsers);
  const adminEmails = users
    .filter((user) => user.role === USER_ROLE.ADMIN && user.enableNotification === true)
    .map((admin) => admin.notificationEmail);

  // Groups
  const allGroups = useSelector(selectGroups);
  const allGroupsName = allGroups.map((item) => item.groupName);
  const initGroup = allGroups.find((item) => configuration.groupId === item.id)
    .groupName;

  // Apps
  const allApps = useSelector(selectApps);

  const getInitialAppStates = () => {
    const initialStates = {};
    allApps.forEach((app) => {
      if (configuration.enabledAppIds.includes(app.id)) {
        initialStates[app.id] = "enabled";
      } else if (configuration.lockedAppIds.includes(app.id)) {
        initialStates[app.id] = "locked";
      } else {
        initialStates[app.id] = "disabled";
      }
    });
    return initialStates;
  };

  const initialAppStates = useMemo(() => getInitialAppStates(), [
    allApps,
    configuration,
  ]);

  const [appStates, setAppStates] = useState(initialAppStates);

  // Force sorting whenever appStates change
  const sortedApps = filterAndSortApps(allApps, appStates)
  

  const appStateEnable = !Object.values(appStates).some(
    (item) => item === "enabled"
  );

  const nextStep = async () => {
    const errors = await formikRef.current.validateForm();
    formikRef.current.setTouched(errors);
    if (Object.keys(errors).length === 0) {
      setActiveStep((prev) => prev + 1);
    } else {
      console.log("Validation errors:", errors);
    }
  };

  // Toggle function for handling app selection
  const toggleApps = (appName) => {
    setAppStates((prev) => {
      const newState = { ...prev };
      newState[appName] =
        newState[appName] === "enabled"
          ? "locked"
          : newState[appName] === "locked"
          ? "disabled"
          : "enabled";
      return newState;
    });
  };

  // Languages
  const allLanguages = useSelector(selectLanguages);

  const allLanguageNames = useMemo(
    () => allLanguages.map((language) => language.languageName),
    [allLanguages]
  );

  const initialLanguageNames = useMemo(
    () =>
      allLanguages
        .filter((language) =>
          configuration.languageIds.includes(language.id)
        )
        .map((language) => language.languageName),
    [allLanguages, configuration.languageIds]
  );

  const [langInitState, setLangInitState] = useState(initialLanguageNames);

  const toggleLang = (langName) => {
    setLangInitState((prev) =>
      prev.includes(langName)
        ? prev.filter((item) => item !== langName)
        : [...prev, langName]
    );
  };

  const sortedLanguages = useMemo(
    () => sortChips(allLanguageNames, langInitState),
    [allLanguageNames, langInitState]
  );

  const disableLang = langInitState.length < 1;

  // Features
  const allFeatures = useSelector(selectFeatures);

  const allFeaturesNames = useMemo(
    () => allFeatures.map((feature) => feature.featureName),
    [allFeatures]
  );

  const initialFeatureNames = useMemo(
    () =>
      allFeatures
        .filter((feature) =>
          configuration.enabledFeatureIds.includes(feature.id)
        )
        .map((feature) => feature.featureName),
    [allFeatures, configuration.enabledFeatureIds]
  );

  const [featureInitState, setFeatureInitState] = useState(initialFeatureNames);

  const toggleFeature = (featureName) => {
    setFeatureInitState((prev) =>
      prev.includes(featureName)
        ? prev.filter((item) => item !== featureName)
        : [...prev, featureName]
    );
  };

  const sortedFeatures = useMemo(
    () => sortChips(allFeaturesNames, featureInitState),
    [allFeaturesNames, featureInitState]
  );

  // Reset form and states on dialog close and initial open
  useEffect(() => {
    if (open) {
      setLangInitState(initialLanguageNames);
      setAppStates(initialAppStates);
      setFeatureInitState(initialFeatureNames);
      setActiveStep(0);
    }
  }, [open, initialAppStates, initialLanguageNames, initialFeatureNames]);

  useEffect(() => {
    filterAndSortApps(allApps, appStates);
  }, [open, appStates, allApps]);

  //submit
  const handleSubmit = async (values, { resetForm }) => {
    const auth = getAuth();
  
    // Close the dialog immediately
    handleClose();
  
    try {
      const {
        configurationName,
        currency,
        platform,
        screen,
        lifetimePrice,
        monthlyPrice,
        maxTrialPeriod,
        groupName,
        addressableURL,
        nonAddressableURL,
      } = values;
  
      // Groups
      const groupId = allGroups.find((item) => item.groupName === groupName).id;
  
      // Apps
      const enabledAppIds = [];
      const lockedAppIds = [];
  
      Object.entries(appStates).forEach(([appId, state]) => {
        const app = allApps.find((app) => app.id === appId);
        if (state === "enabled") {
          enabledAppIds.push(app.id);
        } else if (state === "locked") {
          lockedAppIds.push(app.id);
        }
      });
  
      // Features
      const enabledFeatureIds = allFeatures
        .filter((item) => featureInitState.includes(item.featureName))
        .map((item) => item.id);
  
      // Languages
      const languageIds = allLanguages
        .filter((language) => langInitState.includes(language.languageName))
        .map((language) => language.id);
  
      const updatedAt = new Date();
  
      const isChanged =
        configuration.configurationName !== configurationName ||
        configuration.currency !== currency ||
        configuration.platform !== platform ||
        configuration.screen !== screen ||
        configuration.lifetimePrice !== lifetimePrice ||
        configuration.monthlyPrice !== monthlyPrice ||
        configuration.maxTrialPeriod !== maxTrialPeriod ||
        configuration.groupId !== groupId ||
        configuration.addressableURL !== addressableURL ||
        configuration.nonAddressableURL !== nonAddressableURL ||
        JSON.stringify(configuration.enabledAppIds) !== JSON.stringify(enabledAppIds) ||
        JSON.stringify(configuration.lockedAppIds) !== JSON.stringify(lockedAppIds) ||
        JSON.stringify(configuration.enabledFeatureIds) !== JSON.stringify(enabledFeatureIds) ||
        JSON.stringify(configuration.languageIds) !== JSON.stringify(languageIds);
  
      if (!isChanged) {
        resetForm();
        setActiveStep(0);
        return;
      }
  
      await updateEntry(
        FIREBASE_COLLECTION_NAMES.CONFIGURATIONS,
        configuration.id,
        {
          configurationName,
          enabledAppIds,
          lockedAppIds,
          languageIds,
          currency,
          platform,
          screen,
          lifetimePrice,
          monthlyPrice,
          maxTrialPeriod,
          enabledFeatureIds,
          updatedAt,
          groupId,
          addressableURL,
          nonAddressableURL,
        }
      );
  
      // Emailer start
      const selectedGroup = allGroups.find((group) => group.id === groupId);
      const usersGroupEmails = selectedGroup.userEmails;
      const usersEmails = users.filter(user => usersGroupEmails.includes(user.email) && user.enableNotification === true).map(item => item.notificationEmail);
      const formattedDate = formatTimestamp({
        seconds: new Date().getTime() / 1000,
        nanoseconds: 0,
      });
      const enabledApps = allApps
        .filter((app) => enabledAppIds.includes(app.id))
        .map((app) => app.appName);
      const lockedApps = allApps
        .filter((app) => lockedAppIds.includes(app.id))
        .map((app) => app.appName);
      const enabledFeatures = allFeatures
        .filter((feat) => enabledFeatureIds.includes(feat.id))
        .map((feat) => feat.featureName);
  
      const subject = `"${selectedGroup.groupName}" "${configurationName}" Configuration Edited`;
      const text =
        `The configuration "${configurationName}" was edited by ${auth.currentUser.email} at ${formattedDate}.\n\n ` +
        `Configuration Name: ${configurationName} ${configuration.configurationName !== configurationName ? `(previously: ${configuration.configurationName})` : ""}\n ` +
        `Currency: ${currency}\n ` +
        `Platform: ${platform}\n ` +
        `Screen Size: ${screen}\n ` +
        `Lifetime Price: ${lifetimePrice}\n ` +
        `Monthly Price: ${monthlyPrice}\n ` +
        `Max Trial Period: ${maxTrialPeriod}\n ` +
        `Group Name: ${groupName}\n ` +
        `Enabled Apps: ${enabledApps.join(", ")}\n ` +
        `Locked Apps: ${lockedApps.join(", ")}\n ` +
        `Languages: ${allLanguages
          .filter((lang) => langInitState.includes(lang.languageName))
          .map((lang) => lang.languageCode)
          .join(", ")}\n ` +
        `Features: ${enabledFeatures.join(", ")}\n ` +
        `Addressable URLs: ${addressableURL}\n ` +
        `Non Addressable URLs: ${nonAddressableURL}\n `;
  
      const recipients = [...adminEmails, ...usersEmails];
  
      const emailData = {
        to: recipients,
        subject,
        text,
      };
  
      axios
        .post(
          EMAILER_URL,
          emailData
        )
        .then((response) => {
          console.log("Email sent successfully");
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
      // Emailer end
  
      enqueueSnackbar(`Configuration "${configurationName}" was successfully edited.`, {
        variant: "success",
      });
  
      resetForm();
      setActiveStep(0);
    } catch (error) {
      enqueueSnackbar(
        // eslint-disable-next-line no-undef
        `Failed to edit the configuration. Please try again.`,
        {
          variant: "error",
        }
      );
      console.log("Editing configuration failed: ", error);
    }
  };
  

  // onBlur || closeForm
  useEffect(() => {
    if (!open && formikRef.current) {
      formikRef.current.resetForm();
      setLangInitState(initialLanguageNames);
      setAppStates((prevState) => ({ ...initialAppStates }));
      setFeatureInitState(initialFeatureNames);
      setActiveStep(0);
    }
  }, [open]);

  const FORM_VALIDATION = Yup.object().shape({
    configurationName: Yup.string()
      .test(
        "is-configuration-name-unique",
        "Configuration Name is already used in this group",
        function (value) {
          const groupId = this.parent.groupName
            ? allGroups.find((group) => group.groupName === this.parent.groupName)?.id
            : null;
  
          const configurationsForGroup = allConfigurations.filter(
            (config) =>
              config.groupId === groupId && config.id !== configuration.id
          );
  
          return !configurationsForGroup.some(
            (config) => config.configurationName === value
          );
        }
      )
      .required("Required"),
    groupName: Yup.string().required("Required"),
    currency: Yup.string()
      .matches(/^[A-Za-z]+$/, "Must contain only letters")
      .required("Required"),
    screen: Yup.number()
      .typeError("Must be a number")
      .positive("Must be a positive number")
      .required("Required"),
    lifetimePrice: Yup.number()
      .typeError("Must be a number")
      .required("Required")
      .min(0, "Can’t be negative")
      .test(
        "positive",
        "Can’t be negative",
        (value) => value >= 0
      ),
    monthlyPrice: Yup.number()
      .typeError("Must be a number")
      .required("Required")
      .min(0, "Can’t be negative")
      .test(
        "positive",
        "Can’t be negative",
        (value) => value >= 0
      ),
    maxTrialPeriod: Yup.number()
    .min(0, "Can’t be negative")
    .required("Required")
    .typeError("Must be a number"),
    addressableURL: Yup.string()
      .required("Required")
      .matches(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/, 'Must be URL'),
    nonAddressableURL: Yup.string()
      .required("Required")
      .matches(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/, 'Must be URL'),
  });
  
  
  return (
    <Formik
      initialValues={{
        configurationName: configuration.configurationName,
        currency: configuration.currency,
        screen: configuration.screen,
        platform: configuration.platform,
        lifetimePrice: configuration.lifetimePrice,
        monthlyPrice: configuration.monthlyPrice,
        maxTrialPeriod: configuration.maxTrialPeriod,
        groupName: initGroup,
        addressableURL: configuration.addressableURL,
        nonAddressableURL: configuration.nonAddressableURL,
      }}
      validationSchema={FORM_VALIDATION}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      innerRef={formikRef}
      validateOnChange
      validateOnBlur
    >
      <Form>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
          <DialogTitle>Edit Configuration</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                maxWidth: 400,
                height: 400,
                maxHeight: 400,
                flexGrow: 1,
                "& > * + *": { marginTop: "10px" },
                pt: 2,
              }}
            >
              {activeStep === 0 && (
                <AddGeneralInfoForm allGroups={allGroupsName} />
              )}
              {activeStep === 1 && (
                <AddAppsSelection
                  allAppNames={sortedApps}
                  appStates={appStates}
                  toggleApps={toggleApps}
                />
              )}
              {activeStep === 2 && (
                <AddFeaturesAndLanguages
                  featureInitState={featureInitState}
                  toggleFeature={toggleFeature}
                  languages={sortedLanguages}
                  features={sortedFeatures}
                  toggleLang={toggleLang}
                  langInitState={langInitState}
                />
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button sx={{ color: "red" }} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              onClick={() => setActiveStep((prev) => prev - 1)}
              disabled={activeStep === 0}
              sx={{ color: "#403C8C" }}
            >
              Back
            </Button>
            {activeStep === maxSteps - 1 ? (
              <FormikButton
                type="submit"
                style={{ color: disableLang ? "grey" : "green" }}
                disabled={disableLang}
              >
                Save
              </FormikButton>
            ) : (
              <NextButton
              onClick={nextStep}
              isNextButtonDisabled={false}
              appStateEndable={appStateEnable}
              activeStep={activeStep}
            />
            )}
          </DialogActions>
        </Dialog>
      </Form>
    </Formik>
  );
};

export default ConfigurationEditDialog;
