import { Fragment, useState } from "react";
import { useSelector } from "react-redux";

import {
  selectLicenseOverviewGroup,
  selectLicenseOverviewGroupId,
  selectLicenseOverviewSortBy,
  selectLicenseOverviewStatus,
  selectLicenseOverviewTypes,
  selectLicenseQuery,
} from "../../store/license-overview/license-overview.selector";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AddIcon from "@mui/icons-material/Add";

import LicenseList from "../../components/license-list/license-list.component";
import FloatingActionButton from "../../components/floating-action-button/floating-action-button.component";
import LicenseAddDialog from "../../components/license-list/license-add-dialog.component";
import { Box } from "@mui/material";
import { selectEnhancedUser } from "../../store/user/user.selector";
import { selectConfigurations } from "../../store/configurations/configurations.selector";
import {
  warningConfigAdmin,
  warningConfigurations,
  warningGroup,
  warningLicense,
  warningSearchResult,
} from "../../utils/helpers/warnings";
import { selectLicenses } from "../../store/licenses/licenses.selector";
import useDebounce, { overviewLicenses } from "../../utils/helpers/helpers";
import {
  LICENSE_SORT_BY,
  LICENSE_TYPES,
  USER_ROLE,
} from "../../utils/helpers/constants";
import { selectPayments } from "../../store/payments/payments.selector";
import LicenseOverviewGroupSelection from "../../components/license-list/license-list-subs/license-list-group-selection.component";

const LicensesOverview = () => {
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  // Enhanced User
  const currentUser = useSelector(selectEnhancedUser);
  const isAdmin = currentUser && currentUser.role === USER_ROLE.ADMIN;

  //avalible groups
  const avalibleGroup = useSelector(selectLicenseOverviewGroup);

  //avalible configs
  const allConfiguration = useSelector(selectConfigurations);
  const selectedGroup = useSelector(selectLicenseOverviewGroupId);

  const configurationsForSelectedGroup = allConfiguration.filter((config) =>
    config.groupId === (selectedGroup)
  );


  //Licenses
  const allLicenses = useSelector(selectLicenses);

  //visible licenses
  const payments = useSelector(selectPayments);
  const sortBy = useSelector(selectLicenseOverviewSortBy);
  const filterStatus = useSelector(selectLicenseOverviewStatus);
  const filterType = useSelector(selectLicenseOverviewTypes);
  const selectedQuery = useSelector(selectLicenseQuery);
  const debouncedQuery = useDebounce(selectedQuery, 500);

  const configurationsForSelectedGroupId = configurationsForSelectedGroup.map(item => item.id)


  const filteredLicenses = allLicenses.filter((license) =>
   configurationsForSelectedGroupId.includes(license.configurationId)
  );

  const today = new Date();
  today.setHours(23, 59, 59, 999);

  //sort START
  const sortedLicenses = [
    ...overviewLicenses(
      filteredLicenses,
      filterStatus,
      filterType,
      debouncedQuery,
      payments,
      today
    ),
  ].sort((a, b) => {
    // Ensure lifetime licenses always come first and are sorted by name
    if (a.type === LICENSE_TYPES.LIFETIME && b.type !== LICENSE_TYPES.LIFETIME) {
      return -1;
    } else if (b.type === LICENSE_TYPES.LIFETIME && a.type !== LICENSE_TYPES.LIFETIME) {
      return 1;
    } else if (a.type === LICENSE_TYPES.LIFETIME && b.type === LICENSE_TYPES.LIFETIME) {
      return a.licenseName.localeCompare(b.licenseName);
    }
  
    if (sortBy === LICENSE_SORT_BY.NAME) {
      // Sort by name
      const nameComparison = a.licenseName.localeCompare(b.licenseName);
      if (nameComparison !== 0) {
        return nameComparison;
      } else {
        // If names are the same, sort by key
        return a.licenseKey.localeCompare(b.licenseKey);
      }
    }
    if (sortBy === LICENSE_SORT_BY.CREATION_DATE) {
      // Sort by creation date
      const creationDateDiff =
        new Date(b.createdAt.seconds * 1000) -
        new Date(a.createdAt.seconds * 1000);
      if (creationDateDiff !== 0) {
        return creationDateDiff;
      } else {
        // If creation dates are the same, sort by license name
        return a.licenseName.localeCompare(b.licenseName);
      }
    }
    if (sortBy === LICENSE_SORT_BY.UPDATE_DATE) {
      // Sort by update date or creation date if update date is not available
      const aUpdatedAt = a.updatedAt
        ? new Date(a.updatedAt.seconds * 1000)
        : new Date(a.createdAt.seconds * 1000);
      const bUpdatedAt = b.updatedAt
        ? new Date(b.updatedAt.seconds * 1000)
        : new Date(b.createdAt.seconds * 1000);
      const updateDateDiff = bUpdatedAt - aUpdatedAt;
      if (updateDateDiff !== 0) {
        return updateDateDiff;
      } else {
        // If update dates are the same, sort by license name
        return a.licenseName.localeCompare(b.licenseName);
      }
    }
    if (sortBy === LICENSE_SORT_BY.VALID_FROM) {
      // Sort by valid from date
      const validFromDateDiff =
        new Date(b.validFrom.seconds * 1000) -
        new Date(a.validFrom.seconds * 1000);
      if (validFromDateDiff !== 0) {
        return validFromDateDiff;
      } else {
        // If valid from dates are the same, sort by license name
        return a.licenseName.localeCompare(b.licenseName);
      }
    }
    if (sortBy === LICENSE_SORT_BY.VALID_TILL) {
      // Sort by valid till date or type for lifetime licenses
      const validTillDateDiff =
        new Date(b.validTill.seconds * 1000) -
        new Date(a.validTill.seconds * 1000);
      if (validTillDateDiff !== 0) {
        return validTillDateDiff;
      } else {
        // If valid till dates are the same, sort by license name
        return a.licenseName.localeCompare(b.licenseName);
      }
    }
    return 0; // Default case
  });
  //sort END


  //warnings
  let warningMessage = null;

  if (
    configurationsForSelectedGroup.length === 0 &&
    avalibleGroup.length > 0 &&
    !isAdmin
  ) {
    warningMessage = warningConfigurations;
  } else if (
    configurationsForSelectedGroup.length > 0 &&
    avalibleGroup.length > 0 &&
    !filteredLicenses.length > 0
  ) {
    warningMessage = warningLicense;
  } else if (avalibleGroup.length === 0) {
    warningMessage = warningGroup;
  } else if (
    configurationsForSelectedGroup.length === 0 &&
    avalibleGroup.length > 0 &&
    isAdmin
  ) {
    warningMessage = warningConfigAdmin;
  } else if (
    configurationsForSelectedGroup.length > 0 &&
    avalibleGroup.length > 0 &&
    filteredLicenses.length > 0
  ) {
    warningMessage = warningSearchResult;
  }

  const openAddDialog = () => {
    setIsAddDialogOpen(true);
  };

  const closeAddDialog = () => {
    setIsAddDialogOpen(false);
  };

  const isAdminOrEditor = currentUser.role === USER_ROLE.ADMIN || currentUser.role === USER_ROLE.EDITOR

  const numberOfLicenses =
    filteredLicenses.length === sortedLicenses.length
      ? `${filteredLicenses.length}  licenses`
      : `${sortedLicenses.length} of ${filteredLicenses.length} licenses`;

  return (
    <Fragment>
      <Container>
        <Stack marginTop={4} marginBottom={8} spacing={4}>
          <Typography variant="h5" textAlign="center">
            Licenses
          </Typography>
          <LicenseOverviewGroupSelection filteredLicenses={filteredLicenses} numberOfLicenses={numberOfLicenses} />
          {warningMessage && sortedLicenses.length === 0 ? (
            <Box sx={{ display: "flex", justifyContent: "center", textAlign: "center"}}>
              <Typography>{warningMessage}</Typography>
            </Box>
          ) : (
            <LicenseList overviewLicenses={sortedLicenses} />
          )}
        </Stack>
      </Container>
      {warningMessage !== warningConfigurations &&
        warningMessage !== warningConfigAdmin && isAdminOrEditor && (
          <FloatingActionButton handleClick={openAddDialog}>
            <AddIcon />
          </FloatingActionButton>
        )}
      <LicenseAddDialog open={isAddDialogOpen} handleClose={closeAddDialog} />
    </Fragment>
  );
};

export default LicensesOverview;
