import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { addEntry } from "../../utils/firebase/firebase-firestore.utils";
import { FIREBASE_COLLECTION_NAMES } from "../../utils/firebase/firebase-firestore.utils";
import { generateUID } from "../../utils/uid";

import { selectUsers } from "../../store/users/users.selector";
import { selectGroups } from "../../store/groups/groups.selector";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import FormikButton from "../formik-button/formik-button.component";
import FormikTextfield from "../formik-textfield/formik-textfield.component";
import FormikAutocomplete from "../formik-autocomplete/formik-autocomplete.component";
import React, { useEffect } from "react";
import { formatTimestamp } from "../../utils/helpers/helpers";
import { getAuth } from "firebase/auth";
import axios from "axios";
import { EMAILER_URL, USER_ROLE } from "../../utils/helpers/constants";

const GroupAddDialog = ({ open, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const formikRef = React.useRef();

  // Groups
  const allGroups = useSelector(selectGroups);

  // All Users
  const allUsers = useSelector(selectUsers);
  const allUserNames = allUsers.reduce((acc, user) => {
    acc.push(user.email);
    return acc;
  }, []);

  //Admin users
  const adminEmails = allUsers
    .filter(
      (user) =>
        user.role === USER_ROLE.ADMIN && user.enableNotification === true
    )
    .map((admin) => admin.notificationEmail);

  const handleSubmit = async (values, { resetForm }) => {
    const { groupName, userEmails } = values;
    const auth = getAuth();

    // Close the dialog immediately
    handleClose();

    try {
      const id = generateUID();

      addEntry(FIREBASE_COLLECTION_NAMES.GROUPS, id, {
        id,
        groupName,
        userEmails,
      });

      enqueueSnackbar(`Group "${groupName}" was successfully added.`, {
        variant: "success",
      });

      // Emailer start
      const formattedDate = formatTimestamp({
        seconds: new Date().getTime() / 1000,
        nanoseconds: 0,
      });
      const subject = `"${groupName}" Group Added`;
      const text =
        `The group "${groupName}" got added by ${auth.currentUser.email} at ${formattedDate}.\n \n` +
        `Group Name: ${groupName} \n` +
        `Assigned Users: ${userEmails.join(", ")}`;
      const recipients = [...adminEmails];

      const emailData = {
        to: recipients,
        subject,
        text,
      };

      if (recipients.length > 0) {
        axios
          .post(EMAILER_URL, emailData)
          .then((response) => {
            console.log("Email sent successfully");
          })
          .catch((error) => {
            console.error("Error sending email:", error);
          });
      }

      //Emailer end

      resetForm();
    } catch (error) {
      enqueueSnackbar(
        `Failed to add the group "${groupName}". Please try again.`,
        { variant: "error" }
      );
      console.log("adding group failed: ", error);
    }
  };

  useEffect(() => {
    if (!open && formikRef.current) {
      formikRef.current.resetForm();
    }
  }, [open]);

  const FORM_VALIDATION = Yup.object().shape({
    groupName: Yup.string()
      .test("is-group-name-unique", "Already used", (value) => {
        return !allGroups.some((group) => group.groupName === value);
      })
      .required("Required"),
    userEmails: Yup.array()
      .min(1, "Required")
      .of(Yup.string().email("Invalid email")),
  });

  return (
    <Formik
      initialValues={{ groupName: "", userEmails: [] }}
      validationSchema={FORM_VALIDATION}
      onSubmit={handleSubmit}
      innerRef={formikRef}
      enableReinitialize={true}
    >
      {({ errors, touched }) => (
        <Form>
          <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
            <DialogTitle>Add New Group</DialogTitle>
            <DialogContent>
              <Stack
                direction="column"
                spacing={1}
                marginY={2}
                alignItems="start"
              >
                <FormikTextfield
                  name="groupName"
                  fullWidth
                  label="Group Name"
                  variant="standard"
                />
                <FormikAutocomplete
                  name="userEmails"
                  label="Assigned Users"
                  options={allUserNames}
                  multiple
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} sx={{ color: "red" }}>
                Cancel
              </Button>
              <FormikButton sx={{ color: "green" }}>Save</FormikButton>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

export default GroupAddDialog;
