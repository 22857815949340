import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { ClearIcon } from "@mui/x-date-pickers";
import FormikAutocomplete from "../formik-autocomplete/formik-autocomplete.component";
import { Formik } from "formik";

const ConfigurationSearchBar = ({
  handleSearch,
  query,
  groups,
  handleGroups,
  allGroups
}) => {
 
  return (
    <Formik
      initialValues={{ groups: groups }}
      enableReinitialize={true}
      onValuesChange={(values) => handleGroups(values.groups)}
    >
      <Box display="flex" flexDirection="column" gap="20px">
      {allGroups.length >= 1 && <FormikAutocomplete
          name="groups"
          options={allGroups.length === 0 ? ["No groups found"] : allGroups}
          label="Groups"
          multiple={true}
          handleGroups={handleGroups}
        />}
        <TextField
          variant="outlined"
          value={query}
          onChange={(event) => handleSearch(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {query !== "" && (
                  <IconButton onClick={() => handleSearch("")}>
                    <ClearIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Formik>
  );
};

export default ConfigurationSearchBar;
