import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  ListItem,
  ListItemAvatar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatTimestamp } from "../../../../utils/helpers/helpers";
import {
  CHANGELOG_TYPES,
  USER_ROLE,
} from "../../../../utils/helpers/constants";
import { selectEnhancedUser } from "../../../../store/user/user.selector";
import { getDocs, query, collection, where } from "firebase/firestore";
import { db } from "../../../../utils/firebase/firebase.utils";
import LoadingSpinner from "../../../loading/loading-spinner.component";

const LicenseChangeLog = ({
  open,
  dialogTitle,
  declineText,
  handleClose,
  handleDeclineAction,
  license,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const currentUser = useSelector(selectEnhancedUser);
  const [sortedChangeLogs, setSortedChangeLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchChangeLogs = async () => {
      if (!open || !license?.id) return;

      try {
        setIsLoading(true);

        const q = query(
          collection(db, "licensesChangelog"),
          where("licenseId", "==", license.id)
        );
        const querySnapshot = await getDocs(q);

        const fetchedLogs = querySnapshot.docs.map((doc) => doc.data());

        const sortedLogs = fetchedLogs
          .sort((a, b) => {
            const aTimestamp =
              a.timestamp.seconds * 1e9 + a.timestamp.nanoseconds;
            const bTimestamp =
              b.timestamp.seconds * 1e9 + b.timestamp.nanoseconds;
            return bTimestamp - aTimestamp;
          })
          .map((log) => {
            if (currentUser.role !== USER_ROLE.ADMIN) {
              return {
                ...log,
                changedFields: log.changedFields.filter(
                  (field) => field.fieldName !== "adminComment"
                ),
              };
            }
            return log;
          });

        setSortedChangeLogs(sortedLogs);
      } catch (error) {
        console.error("Error fetching change logs:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchChangeLogs();

    return () => {
      setSortedChangeLogs([]);
    };
  }, [open, license?.id, currentUser.role]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Box>{dialogTitle}</Box>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "-5em",
            }}
          >
            <LoadingSpinner />
          </div>
        ) : (
          sortedChangeLogs.map((logs, index) => (
            <Paper
              key={index}
              sx={{ mb: 1, borderRadius: "16px", p: 2, mt: 3 }}
              elevation={1}
            >
              <Box>
                <ListItem
                  sx={{
                    paddingLeft: windowWidth > 520 ? "16px" : "0px",
                    paddingRight: windowWidth > 520 ? "16px" : "0px",
                    pt: 0,
                    pb: "13px",
                  }}
                >
                  {windowWidth > 520 && (
                    <ListItemAvatar>
                      {logs.changeType === CHANGELOG_TYPES.CREATE && (
                        <Avatar sx={{ backgroundColor: "green" }}>C</Avatar>
                      )}
                      {logs.changeType === CHANGELOG_TYPES.UPDATE && (
                        <Avatar sx={{ backgroundColor: "#403C8C" }}>U</Avatar>
                      )}
                    </ListItemAvatar>
                  )}
                  {windowWidth > 520 ? (
                    <Box ml={2} flex="1">
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        gap="30px"
                        width="100%"
                      >
                        <Box>{formatTimestamp(logs.timestamp)}</Box>
                        <Box>{logs.lastChangedBy}</Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box ml={0} flex="1">
                      <Box
                        display="flex"
                        justifyContent="left"
                        flexDirection="column"
                        gap="5px"
                        width="100%"
                      >
                        <Box>{formatTimestamp(logs.timestamp)}</Box>
                        <Box>{logs.lastChangedBy}</Box>
                      </Box>
                    </Box>
                  )}
                </ListItem>
              </Box>
              <Grid item xs={12} sx={{ pt: "5px" }}>
                <Divider />
              </Grid>
              {logs.changedFields.length > 0 && (
                <TableContainer component={Paper} sx={{ mt: "16px" }}>
                  <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" sx={{ p: "10px" }}>
                          Field
                        </TableCell>
                        <TableCell align="left" sx={{ p: "10px" }}>
                          From
                        </TableCell>
                        <TableCell align="left" sx={{ p: "10px" }}>
                          To
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {logs.changedFields.map((item, idx) => {
                        const formatValue = (value) => {
                          if (Array.isArray(value) && value.length > 0) {
                            return value.join(", ");
                          } else if (
                            value === null ||
                            value === "" ||
                            (Array.isArray(value) && value.length === 0)
                          ) {
                            return "N/A";
                          } else if (typeof value === "boolean") {
                            return value ? "True" : "False";
                          } else {
                            const formattedValue = formatTimestamp(value);
                            return formattedValue === null ||
                              formattedValue === ""
                              ? "N/A"
                              : formattedValue;
                          }
                        };

                        return (
                          <TableRow key={idx}>
                            <TableCell
                              align="left"
                              sx={{ width: "33%", p: "10px" }}
                            >
                              {item.fieldName}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ width: "33%", p: "10px" }}
                            >
                              {formatValue(item.from)}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ width: "33%", p: "10px" }}
                            >
                              {formatValue(item.to)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Paper>
          ))
        )}
      </DialogContent>
      <DialogActions>
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button sx={{ color: "red" }} onClick={handleDeclineAction}>
            {declineText}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default LicenseChangeLog;
