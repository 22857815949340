import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ConfirmDeclineDialog from "../../../confirm-decline-dialog/confirm-decline-dialog.component";
import { useSnackbar } from "notistack";
import {
  FIREBASE_COLLECTION_NAMES,
  deleteEntry,
} from "../../../../utils/firebase/firebase-firestore.utils";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  formatDate,
  formatTimestamp,
} from "../../../../utils/helpers/helpers";
import { useSelector } from "react-redux";
import { selectEnhancedUser } from "../../../../store/user/user.selector";
import LicensePaymentDialog from "./license-payment-dialog.component";
import LicensePaymentEditDialog from "./license-payment-edit.component";
import { warningNoPaymentHistory } from "../../../../utils/helpers/warnings";
import { EMAILER_URL, LICENSE_TYPES, PAYMENT_TYPES, USER_ROLE } from "../../../../utils/helpers/constants";
import axios from "axios";
import { selectConfigurations } from "../../../../store/configurations/configurations.selector";
import { selectGroups } from "../../../../store/groups/groups.selector";
import { getAuth } from "firebase/auth";
import { selectUsers } from "../../../../store/users/users.selector";

const LicensePaymentHistory = ({
  open,
  dialogTitle,
  dialogData,
  declineText,
  handleClose,
  handleDeclineAction,
  license,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  
  const allConfiguration = useSelector(selectConfigurations)
  const allGroups = useSelector(selectGroups)
  const users = useSelector(selectUsers);
  const adminEmails = users
    .filter((user) => user.role === USER_ROLE.ADMIN && user.enableNotification === true)
    .map((user) => user.notificationEmail);
  

  //User
  const user = useSelector(selectEnhancedUser);
  const isAdmin = user.role === USER_ROLE.ADMIN;

  const handleDelete = async () => {
    const auth = getAuth()

    try {
      await deleteEntry(FIREBASE_COLLECTION_NAMES.PAYMENTS, selectedPayment.id);

       // Emailer start
       const selectedConfigId = allConfiguration.find(
        (config) => config.id === license.configurationId
      ).groupId;
      const selectedGroup = allGroups.find(
        (group) => group.id === selectedConfigId
      );
      const usersGroupEmails = selectedGroup.userEmails;
      const usersEmails = users.filter(user => usersGroupEmails.includes(user.email) && user.enableNotification === true).map(item => item.notificationEmail)
      const formattedDate = formatTimestamp({
        seconds: new Date().getTime() / 1000,
        nanoseconds: 0,
      });

      const subject = `"${selectedGroup.groupName}" "${license.licenseName}" Payment Deleted`;
      const text =
        `A payment for the license "${license.licenseName}" was deleted by ${auth.currentUser.email} at ${formattedDate} \n\n ` +
        `License Name: ${license.licenseName}\n ` +
        `License Key: ${selectedPayment.licenseKey}\n ` +
        `Payment Type: ${selectedPayment.paymentType}\n ` +
        `Currency: ${selectedPayment.currency}\n ` +
        `Amount: ${selectedPayment.amount}\n ` +
        `Payed At: ${formatDate(selectedPayment.payedAt)}\n ` +
        `Payed Till: ${
          selectedPayment.paymentType === PAYMENT_TYPES.LIFETIME
            ? "N/A"
            : formatDate(selectedPayment.payedTill)
        }\n ` +
        `Comment: ${selectedPayment.comment.trim()}\n `;

      const recipients = [...adminEmails, ...usersEmails];

      const emailData = {
        to: recipients,
        subject,
        text,
      };

      axios
        .post(
          EMAILER_URL,
          emailData
        )
        .then((response) => {
          console.log("Email sent successfully");
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
      // Emailer end


      enqueueSnackbar(`Payment of license "${license.licenseName}" was successfully deleted.`, { variant: "success" });

      setIsDeleteDialogOpen(false);
      setSelectedPayment(null);
    } catch (error) {
      enqueueSnackbar(`Failed to delete the payment of the license "${license.licenseName}". Please try again.`, {
        variant: "error",
      });
      console.error("Payment deletion failed:", error);
    }
  };

  const handleEdit = (item) => {
    setSelectedPayment(item);
    setIsEditDialogOpen(true);
  };

  const handleItemDelete = (payment) => {
    setSelectedPayment(payment);
    setIsDeleteDialogOpen(true);
  };

  const paidHistory = dialogData.some((item) => item.payedAt);
  const sortHistoryList = dialogData.sort(
    (a, b) => a.payedAt.seconds - b.payedAt.seconds
  );

  const hasComments = sortHistoryList.some(item => item.comment && item.comment.trim() !== "");

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>
          <Box>{dialogTitle}</Box>
        </DialogTitle>
        <DialogContent>
          {dialogData.length > 0 && paidHistory ? (
             <TableContainer component={Paper} style={{ paddingRight: "0px", marginRight: "0px" }}>
             <Table>
               <TableHead>
                 <TableRow>
                   <TableCell align="center">Payed At</TableCell>
                   <TableCell align="center">Paid Till</TableCell>
                   <TableCell align="center">Amount</TableCell>
                   <TableCell align="center">Payment Type</TableCell>
                   {hasComments && <TableCell align="center">Comments</TableCell>}
                   {isAdmin && <TableCell align="center">{""}</TableCell>}
                 </TableRow>
               </TableHead>
               <TableBody>
                 {sortHistoryList.map((item) => (
                   <TableRow key={item.id}>
                     <TableCell align="center">{formatDate(new Date(item.payedAt.seconds * 1000))}</TableCell>
                     <TableCell align="center">
                       {item.paymentType === LICENSE_TYPES.LIFETIME ? "N/A" : formatDate(new Date(item.payedTill.seconds * 1000))}
                     </TableCell>
                     <TableCell align="center">{`${item.currency} ${item.amount}`}</TableCell>
                     <TableCell align="center">{item.paymentType}</TableCell>
       
                     {hasComments && (
                       <TableCell align="center">
                         {item.comment && (
                           <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                             <div style={{ wordBreak: "break-word", maxWidth: "150px", overflow: "auto", maxHeight: "100px" }}>
                               {item.comment}
                             </div>
                           </div>
                         )}
                       </TableCell>
                     )}
       
                     {isAdmin && (
                       <TableCell align="center">
                         <Box display="flex" gap="10px" justifyContent="flex-end">
                           <IconButton
                             sx={{ backgroundColor: "#403C8C", color: "white", ":hover": { color: "#403C8C" } }}
                             onClick={() => handleEdit(item)}
                           >
                             <EditIcon />
                           </IconButton>
                           <IconButton color="error" onClick={() => handleItemDelete(item)}>
                             <DeleteIcon />
                           </IconButton>
                         </Box>
                       </TableCell>
                     )}
                   </TableRow>
                 ))}
               </TableBody>
             </Table>
           </TableContainer>
          ) : (
            <Typography>{warningNoPaymentHistory}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            {isAdmin && (
              <Button
              onClick={() => setIsPaymentDialogOpen(true)} 
              sx={{
                color: "#403C8C",
              }} variant="text">Add Payment</Button>
            )}
             
            <Button sx={{ color: "red" }} onClick={handleDeclineAction}>
              {declineText}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {license && (
        <LicensePaymentDialog
          open={isPaymentDialogOpen}
          handleClose={() => setIsPaymentDialogOpen(false)}
          license={license}
        />
      )}

      {selectedPayment && isAdmin && (
        <LicensePaymentEditDialog
          open={isEditDialogOpen}
          handleClose={() => setIsEditDialogOpen(false)}
          payment={selectedPayment}
        />
      )}

      <ConfirmDeclineDialog
        open={isDeleteDialogOpen}
        dialogTitle="Delete Payment"
        dialogText="Are you sure that you want to delete this payment? This operation cannot be undone."
        confirmText="Confirm"
        declineText="Cancel"
        handleClose={() => setIsDeleteDialogOpen(false)}
        handleConfirmAction={handleDelete}
        handleDeclineAction={() => setIsDeleteDialogOpen(false)}
      />
    </>
  );
};

export default LicensePaymentHistory;
