
//Licenses
export const warningLicense =
  `No license has been created yet. Click the "Add" button at the bottom right of the page to create your first license.`;

export const warningConfigurations =
  "You don't have any configurations. This should not happen. Please contact your administrator or email “support@brainssistance.com”.";

export const warningGroup = "You aren't assigned to any group. This should not happen. Please contact your administrator or email “support@brainssistance.com”.";

export const warningConfigAdmin = "No configuration has been created for this group. Please create a configuration before proceeding with license creation.";

export const warningSearchResult = "No license matches your search and filters."
//Licenses

//Configurations
export const warningConfigurationNoConfig = `No configuration has been created yet. Click the "Add" button at the bottom right of the page to create your first configuration.`

export const warningConfigurationNoConfigUser = "You don't have any configurations. This should not happen. Please contact your administrator or email “support@brainssistance.com”."

export const warningConfigurationNoGroup = "You aren't assigned to any group. This should not happen. Please contact your administrator or email “support@brainssistance.com”."

export const warningConfigurationNoResults = "No configuration matches your search."
//Configurations

//Apps
export const warningNoAppsResults = "No app matches your search."
//Apps

//Features
export const warnignNoFeatureResults = "No feature matches your search."
//Features

//Languages
export const warningNoLanguagesResults = "No language matches your search."
//Languages

//Groups
export const warningNoGroup = `No group has been created yet. Click the "Add" button at the bottom right of the page to create your first group.`
export const warningNoGroupResults = "No group matches your search."
//Groups

//Users
export const warningNoUsersResults = "No user matches your search."
//Users

//Payments
export const warningNoPaymentHistory = `No payment has been created yet. Click the "Add Payment" button to create the first payment.`
//Payments

//Auth
export const warningAuthNotCorrect = "The email or password you entered is incorrect."