import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from "@mui/material";
import { selectLanguagesListSearchString } from "../../store/languages/languages.selector";
import { setLanguagesListSearchString } from "../../store/languages/languages.action";

const LanguageListSearchBar = () => {
    const dispatch = useDispatch();

    const [showClearIcon, setShowClearIcon] = useState("none");

    const searchString = useSelector(selectLanguagesListSearchString);
    
    const handleChange = (event) => {
        dispatch(setLanguagesListSearchString(event.target.value));
        setShowClearIcon(event.target.value === "" ? "none" : "flex");
    };

    const handleClear = () => {
        dispatch(setLanguagesListSearchString(""));
    }

    return (
        <TextField
            variant="outlined"
            value={searchString}
            onChange={handleChange}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>
                ),
                endAdornment: (
                <InputAdornment
                    position="end"
                    style={{ display: showClearIcon }}
                >
                    <IconButton onClick={handleClear}>
                        <ClearIcon />
                    </IconButton>
                </InputAdornment>
                )
            }}
        />
    );
}

export default LanguageListSearchBar;