import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import {
  deleteEntry,
  FIREBASE_COLLECTION_NAMES,
} from "../../utils/firebase/firebase-firestore.utils";

import { selectConfigurations } from "../../store/configurations/configurations.selector";
import { selectUsers } from "../../store/users/users.selector";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import GroupEditDialog from "./group-edit-dialog.component";
import ConfirmDeclineDialog from "../confirm-decline-dialog/confirm-decline-dialog.component";
import {
  Avatar,
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";
import React from "react";
import axios from "axios";
import { formatTimestamp } from "../../utils/helpers/helpers";
import { getAuth } from "firebase/auth";
import {
  COLOR_PURPLE,
  EMAILER_URL,
  USER_ROLE,
} from "../../utils/helpers/constants";

const GroupListItem = ({ group }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const { groupName, userEmails } = group;

  //Config
  const allConfiguration = useSelector(selectConfigurations);
  const assignedConfiguration = allConfiguration.find(
    (config) => config.groupId === group.id
  );
  const isAssignedToConfig = assignedConfiguration ? true : false;

  // Assigned Users
  const allUsers = useSelector(selectUsers);
  const assignedUserEmails = allUsers.reduce((acc, user) => {
    if (userEmails.includes(user.email)) {
      acc.push(user.email);
    }
    return acc;
  }, []);

  //Admin users
  const adminEmails = allUsers
    .filter(
      (user) =>
        user.role === USER_ROLE.ADMIN && user.enableNotification === true
    )
    .map((admin) => admin.notificationEmail);

  const handleDelete = async (event) => {
    const auth = getAuth();
    event.preventDefault();

    try {
      await deleteEntry(FIREBASE_COLLECTION_NAMES.GROUPS, group.id);
      enqueueSnackbar(`Group "${group.groupName}" was successfully deleted.`, {
        variant: "success",
      });

      // Emailer start
      const formattedDate = formatTimestamp({
        seconds: new Date().getTime() / 1000,
        nanoseconds: 0,
      });
      const subject = `"${groupName}" Group Deleted`;
      const text =
        `The group "${groupName}" got deleted by ${auth.currentUser.email} at ${formattedDate}.\n \n` +
        `Group Name: ${groupName} \n` +
        `Assigned Users: ${userEmails.join(", ")}`;
      const recipients = [...adminEmails];

      const emailData = {
        to: recipients,
        subject,
        text,
      };

      if (recipients.length > 0) {
        axios
          .post(EMAILER_URL, emailData)
          .then((response) => {
            console.log("Email sent successfully");
          })
          .catch((error) => {
            console.error("Error sending email:", error);
          });
      }

      //Emailer end

      setIsDeleteDialogOpen(false);
    } catch (error) {
      enqueueSnackbar(
        `Failed to delete the group "${groupName}". Please try again.`,
        { variant: "error" }
      );
      console.log("group deletion failed: ", error);
    }
  };

  const openEditDialog = () => {
    setIsEditDialogOpen(true);
  };

  const closeEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  return (
    <Paper
      sx={{
        mb: 1,
        borderRadius: "16px",
        minHeight: "72px",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        width: "100%",
        "@media (max-width: 600px)": {
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "8px",
        },
      }}
      elevation={1}
    >
      <ListItem>
        {windowWidth > 520 && (
          <ListItemAvatar>
            <Avatar
              style={{
                backgroundColor: `${COLOR_PURPLE}`,
              }}
            >
              {groupName[0]}
            </Avatar>
          </ListItemAvatar>
        )}
        {windowWidth > 520 ? (
          <ListItemText
            sx={{ wordBreak: "break-word" }}
            primary={groupName}
            secondary={assignedUserEmails.join(", ")}
          />
        ) : (
          <ListItemText
            sx={{ display: "flex", flexDirection: "column" }}
            primary={groupName}
            secondary={assignedUserEmails.join(", ")}
          />
        )}
        <Box display="flex" gap="10px">
          <IconButton
            onClick={openEditDialog}
            sx={{
              backgroundColor: "#403C8C",
              color: "white",
              ":hover": { color: "#403C8C" },
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="error"
            onClick={openDeleteDialog}
            disabled={isAssignedToConfig}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </ListItem>
      <GroupEditDialog
        open={isEditDialogOpen}
        handleClose={closeEditDialog}
        group={group}
      />
      <ConfirmDeclineDialog
        open={isDeleteDialogOpen}
        dialogTitle="Delete Group"
        dialogText="Are you sure that you want to delete this group? This operation cannot be undone."
        confirmText="Confirm"
        declineText="Cancel"
        handleClose={closeDeleteDialog}
        handleConfirmAction={handleDelete}
        handleDeclineAction={closeDeleteDialog}
      />
    </Paper>
  );
};

export default GroupListItem;
