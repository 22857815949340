import { USERS_ACTION_TYPES } from "./users.types";
import { createAction } from "../../utils/reducer/reducer";

export const setUsers = (users) => {    
    users.sort((a, b) => a.displayName.localeCompare(b.displayName));
    return createAction(USERS_ACTION_TYPES.SET_USERS, users);
}

export const setUsersListSearchString = (searchString) => {
    return createAction(USERS_ACTION_TYPES.SET_USERS_LIST_SEARCH_STRING, searchString);
}; 