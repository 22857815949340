import { useSelector } from "react-redux";

import List from "@mui/material/List";

import {
  selectFeatures,
  selectFeaturesListSearchString,
} from "../../store/features/features.selector";
import FeaturesListItem from "./features-list-item.component";
import useDebounce, { alphanumericSort } from "../../utils/helpers/helpers";
import { Typography } from "@mui/material";
import { warnignNoFeatureResults } from "../../utils/helpers/warnings";

const FeaturesList = () => {
  const features = useSelector(selectFeatures);

  const queryFeatures = useSelector(selectFeaturesListSearchString);
  const debounceQuery = useDebounce(queryFeatures, 500);

  const visibleFeatures = features
    .filter(
      (feature) =>
        feature.featureName
          .toLowerCase()
          .includes(debounceQuery.toLowerCase()) ||
        feature.uuid.toLowerCase().includes(debounceQuery.toLowerCase())
    )
    .sort((a, b) => alphanumericSort(a, b, "featureName"));

  return (
    <List>
      {visibleFeatures.length > 0 ? (
        visibleFeatures.map((feature, idx) => (
          <FeaturesListItem key={idx} feature={feature} />
        ))
      ) : (
        <Typography textAlign="center">{warnignNoFeatureResults}</Typography>
      )}
    </List>
  );
};

export default FeaturesList;
