import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { selectApps } from "../../store/apps/apps.selector";

import { updateEntry } from "../../utils/firebase/firebase-firestore.utils";
import { FIREBASE_COLLECTION_NAMES } from "../../utils/firebase/firebase-firestore.utils";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import FormikButton from "../formik-button/formik-button.component";
import FormikTextfield from "../formik-textfield/formik-textfield.component";
import { selectUsers } from "../../store/users/users.selector";
import { useEffect, useRef } from "react";
import { formatTimestamp } from "../../utils/helpers/helpers";
import { getAuth } from "firebase/auth";
import axios from "axios";
import { EMAILER_URL, USER_ROLE } from "../../utils/helpers/constants";

const AppEditDialog = ({ open, handleClose, app }) => {
  const { enqueueSnackbar } = useSnackbar();
  const formikRef = useRef();

  const allApps = useSelector(selectApps);
  const users = useSelector(selectUsers);
  const adminEmails = users
    .filter((user) => user.role === USER_ROLE.ADMIN && user.enableNotification === true)
    .flatMap((user) => user.notificationEmail);

  useEffect(() => {
    if (!open && formikRef.current) {
      formikRef.current.resetForm();
    }
  }, [open]);

  const handleSubmit = async (values, { resetForm }) => {
    const { appName, uuid } = values;
    const updatedAt = new Date();
    const auth = getAuth();

    if (appName === app.appName && uuid === app.uuid) {
      resetForm();
      handleClose();

      return;
    }

    // Close the dialog immediately
    handleClose();

    try {
      await updateEntry(FIREBASE_COLLECTION_NAMES.APPS, app.id, {
        id: app.id,
        uuid: uuid,
        appName,
        updatedAt,
      });

      enqueueSnackbar(`App "${appName}" was successfully edited.`, {
        variant: "success",
      });

      // Emailer start
      const formattedDate = formatTimestamp({
        seconds: new Date().getTime() / 1000,
        nanoseconds: 0,
      });
      const subject = `"${appName}" App Edited`;
      const text =
        `The app "${appName}" got edited by ${auth.currentUser.email} at ${formattedDate}.\n \n` +
        `App Name: ${appName} \n` +
        `App UUID: ${uuid}`;
      const recipients = [...adminEmails];

      const emailData = {
        to: recipients,
        subject,
        text,
      };

      axios
        .post(EMAILER_URL, emailData)
        .then((response) => {
          console.log("Email sent successfully");
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
      //Emailer end

      resetForm();
    } catch (error) {
      enqueueSnackbar(
        `Failed to edit the app "${appName}". Please try again.`,
        { variant: "error" }
      );
      console.error("editing app failed:", error);
    }
  };

  const FORM_VALIDATION = Yup.object().shape({
    appName: Yup.string()
      .test("is-app-name-unique", "Already used", (value) => {
        return (
          allApps.filter(
            (existingApp) =>
              existingApp.appName === value && existingApp.uuid !== app.uuid
          ).length === 0
        );
      })
      .required("Required"),
  });

  return (
    <Formik
      initialValues={{ uuid: app.uuid, appName: app.appName }}
      validationSchema={FORM_VALIDATION}
      onSubmit={handleSubmit}
      innerRef={formikRef}
      enableReinitialize={true}
    >
      <Form>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
          <DialogTitle>Edit App</DialogTitle>
          <DialogContent>
            <Stack
              direction="column"
              spacing={2}
              marginY={2}
              alignItems="start"
            >
              <FormikTextfield
                name="appName"
                fullWidth
                label="App Name"
                variant="standard"
              />
              <FormikTextfield
                name="uuid"
                fullWidth
                label="App UUID"
                variant="standard"
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button sx={{ color: "red" }} onClick={handleClose}>
              Cancel
            </Button>
            <FormikButton type="submit" sx={{ color: "green" }}>
              Save
            </FormikButton>
          </DialogActions>
        </Dialog>
      </Form>
    </Formik>
  );
};

export default AppEditDialog;
