import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectEnhancedUser } from "../../store/user/user.selector";

import LoadingToRedirect from '../../components/loading/loading-to-redirect.component';

const AuthRouteUser = () => {
    const currentUser = useSelector(selectEnhancedUser);
    
    return currentUser ? <Outlet/> : <LoadingToRedirect redirectTo="/"/>;
};

export default AuthRouteUser;