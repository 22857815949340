// @ts-nocheck
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { selectApps } from "../../store/apps/apps.selector";
import { selectLanguages } from "../../store/languages/languages.selector";

import {
  deleteEntry,
  FIREBASE_COLLECTION_NAMES,
} from "../../utils/firebase/firebase-firestore.utils";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import ConfigurationEditDialog from "./configuration-edit-dialog.component";
import ConfirmDeclineDialog from "../confirm-decline-dialog/confirm-decline-dialog.component";
import React from "react";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";
import { selectGroups } from "../../store/groups/groups.selector";
import {
  filterAndSortApps,
  formatTimestamp,
  sortChips,
} from "../../utils/helpers/helpers";
import { selectEnhancedUser } from "../../store/user/user.selector";
import { selectLicenses } from "../../store/licenses/licenses.selector";
import { selectUsers } from "../../store/users/users.selector";
import { selectFeatures } from "../../store/features/features.selector";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { EMAILER_URL, USER_ROLE } from "../../utils/helpers/constants";
import { COLOR_PURPLE } from "../../utils/helpers/constants";

const ConfigurationListItem = React.memo(({ configuration }) => {
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector(selectEnhancedUser);

  const isAdmin = user && user.role === USER_ROLE.ADMIN;

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const assignedLicense = useSelector(selectLicenses);

  const licenseIds = assignedLicense.map((license) => license.configurationId);
  const isLicenseWithConfiguration = licenseIds.includes(configuration.id);


 

  //Admin Users
  const users = useSelector(selectUsers);
  const adminEmails = users
    .filter((user) => user.role === USER_ROLE.ADMIN && user.enableNotification === true)
    .map((admin) => admin.notificationEmail);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const {
    configurationName,
    enabledAppIds,
    languageIds,
    lifetimePrice,
    monthlyPrice,
    maxTrialPeriod,
    enabledFeatureIds,
    platform,
    screen,
    lockedAppIds,
    groupId,
    currency,
    addressableURL,
    nonAddressableURL
  } = configuration;

  // Assigned Features
  const allFeatures = useSelector(selectFeatures);
  const assignedFeaturesNames = allFeatures.reduce((acc, feature) => {
    if (enabledFeatureIds.includes(feature.id)) {
      acc.push(feature.featureName);
    }
    return acc;
  }, []);

  const sortedFeatures = useMemo(() => 
    sortChips(allFeatures.map((item) => item.featureName), assignedFeaturesNames), 
    [allFeatures, assignedFeaturesNames]
  );

  // Assigned Apps
  const allApps = useSelector(selectApps);

  // Assign initial app state based on enabledAppIds
  const appStates = useMemo(() => {
    const states = {};
    allApps.forEach((app) => {
      if (enabledAppIds.includes(app.id)) {
        states[app.id] = "enabled";
      } else if (lockedAppIds.includes(app.id)) {
        states[app.id] = "locked";
      } else {
        states[app.id] = "disabled";
      }
    });
    return states;
  }, [enabledAppIds, lockedAppIds, allApps]);
  
  const sortedApps = useMemo(() => 
    filterAndSortApps(allApps, appStates), 
    [allApps, appStates]
  );
  

  //Assigned Groups
  const allGroups = useSelector(selectGroups);
  const groupName = allGroups.find((item) => item.id === groupId).groupName;

  // Assigned Languages
  const allLanguages = useSelector(selectLanguages);
  const assignedLanguageNames = allLanguages.reduce((acc, language) => {
    if (languageIds.includes(language.id)) {
      acc.push(language.languageName);
    }
    return acc;
  }, []);

  const sortedLanguages = useMemo(() => 
    sortChips(allLanguages.map((item) => item.languageName), assignedLanguageNames), 
    [allLanguages, assignedLanguageNames]
  );

  const handleDelete = async () => {
    const auth = getAuth()

    if (!isAdmin) return;
    try {
      deleteEntry(FIREBASE_COLLECTION_NAMES.CONFIGURATIONS, configuration.id);

       // Emailer start
       const selectedGroup = allGroups.find((group) => group.id === groupId);
       const usersGroupEmails = selectedGroup.userEmails;
       const usersEmails = users.filter(user => usersGroupEmails.includes(user.email) && user.enableNotification === true).map(item => item.notificationEmail)
       const formattedDate = formatTimestamp({
         seconds: new Date().getTime() / 1000,
         nanoseconds: 0,
       });
       const enabledApps = allApps
         .filter((app) => enabledAppIds.includes(app.id))
         .map((app) => app.appName);
       const avalibleApps = allApps
         .filter((app) => lockedAppIds.includes(app.id))
         .map((app) => app.appName);
       const enabledFeatures = allFeatures
         .filter((feat) => enabledFeatureIds.includes(feat.id))
         .map((feat) => feat.featureName);
 
       const subject = `"${selectedGroup.groupName}" "${configurationName}" Configuration Deleted`;
       const text =
         `The configuration "${configurationName}" was deleted by ${auth.currentUser.email} at ${formattedDate}.\n\n ` +
         `Configuration Name: ${configurationName}\n ` +
         `Currency: ${currency}\n ` +
         `Platform: ${platform}\n ` +
         `Screen Size: ${screen}\n ` +
         `Lifetime Price: ${lifetimePrice}\n ` +
         `Monthly Price: ${monthlyPrice}\n ` +
         `Max Trial Period: ${maxTrialPeriod}\n ` +
         `Group Name: ${groupName}\n ` +
         `Enabled Apps: ${enabledApps.join(", ")}\n ` +
         `Locked Apps: ${avalibleApps.join(", ")}\n ` +
         `Languages: ${allLanguages.filter(lang => languageIds.includes(lang.id)).map(lang => lang.languageCode).join(", ")}\n ` +
         `Features: ${enabledFeatures.join(", ")}\n ` + 
         `Addressable URLs: ${addressableURL}\n ` +
         `Non Addressable URLs: ${nonAddressableURL}\n `;
       const recipients = [...adminEmails, ...usersEmails];
 
       const emailData = {
         to: recipients,
         subject,
         text,
       };
 
       axios
         .post(
           EMAILER_URL,
           emailData
         )
         .then((response) => {
           console.log("Email sent successfully");
         })
         .catch((error) => {
           console.error("Error sending email:", error);
         });
       //Emailer end

       enqueueSnackbar(`Configuration "${configurationName}" was successfully deleted.`, {
        variant: "success",
      });

      setIsDeleteDialogOpen(false);
    } catch (error) {
      enqueueSnackbar(`Failed to delete the configuration "${configurationName}". Please try again.`, {
        variant: "error",
      });
      console.log("configuration deletion failed: ", error);
    }
  };

  return (
    <Paper sx={{ mb: 1, borderRadius: "16px", p: 2, mt: 3 }} elevation={1}>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ paddingTop: "5px" }}>
          <ListItem sx={{ pl: 0 }}>
            {windowWidth > 520 && (
              <ListItemAvatar>
                <Avatar  style={{
              backgroundColor: `${COLOR_PURPLE}`
            }}>{configurationName[0]}</Avatar>
              </ListItemAvatar>
            )}
            <ListItemText
              primary={configurationName}
              secondary={groupName ? `${groupName}` : null}
            />
            {isAdmin && <Box display="flex" gap="10px">
              <IconButton
                sx={{
                  backgroundColor: `${COLOR_PURPLE}`,
                  color: "white",
                  ":hover": { color: `${COLOR_PURPLE}` },
                }}
                onClick={() => setIsEditDialogOpen(true)}
                disabled={!isAdmin}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                color="error"
                disabled={!isAdmin || isLicenseWithConfiguration}
                onClick={() => setIsDeleteDialogOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>}
          </ListItem>
        </Grid>

        <Grid item xs={12} style={{ paddingTop: "5px" }}>
          <Divider />
        </Grid>

        <Grid item xs={12} md={6} sx={{ pt: 1.5 }}>
        <Box sx={{ mt: 1.5 }}>
            <Typography variant="subtitle1" fontWeight={500}>
              General
            </Typography>
            <Box sx={{ mt: "3px" }}>
              <Box display="grid" gridTemplateColumns="40% 60%">
                <Typography noWrap>{'Platform'}</Typography>
                <Typography>{Array.isArray(platform) ? platform.join(", ") : platform}</Typography>
                <Typography noWrap>{'Screen Size'}</Typography>
                <Typography>{screen ? `${screen}-inches` : " -"}</Typography>
              </Box>
            </Box>
          </Box>

          <Grid item xs={12} style={{ paddingTop: "5px" }}>
            <Divider />
          </Grid>

          <Box sx={{ mt: 1.5 }}>
            <Typography variant="subtitle1" fontWeight={500}>
              Pricing
            </Typography>
            <Box sx={{ mt: "3px" }}>
              <Box display="grid" gridTemplateColumns="40% 60%">
                <Typography noWrap>{'Lifetime Price'}</Typography>
                <Typography>{currency} {lifetimePrice}</Typography>
                <Typography noWrap>{'Monthly Price'}</Typography>
                <Typography>{currency} {monthlyPrice}</Typography>
                <Typography noWrap>{'Trial Period Duration'}</Typography>
                <Typography>{maxTrialPeriod} days</Typography>
              </Box>
            </Box>
          </Box>

          <Grid item xs={12} style={{ paddingTop: "5px" }}>
            <Divider />
          </Grid>

          <Box>
            <Typography variant="subtitle1" sx={{ mt: 1 }} fontWeight={500}>
              Languages
            </Typography>
            <Box sx={{ mt: 1 }}>
              {sortedLanguages.length > 0 ? (
                sortedLanguages.map((lang) => (
                  <Chip
                    key={lang}
                    label={lang}
                    color={
                      assignedLanguageNames.includes(lang) ? "success" : "error"
                    }
                    sx={{ marginRight: 1, marginBottom: 1 }}
                  />
                ))
              ) : (
                <Typography variant="body2">No Languages Avaliable</Typography>
              )}
            </Box>
          </Box>

          <Grid item xs={12} style={{ paddingTop: "5px" }}>
            <Divider />
          </Grid>

          <Box>
            <Typography variant="subtitle1" sx={{ mt: 1 }} fontWeight={500}>
              Features
            </Typography>
            <Box sx={{ mt: 1 }}>
              {sortedFeatures.length > 0 ? (
                sortedFeatures.map((feat) => (
                  <Chip
                    key={feat}
                    label={feat}
                    color={
                      assignedFeaturesNames.includes(feat) ? "success" : "error"
                    }
                    sx={{ marginRight: 1, marginBottom: 1 }}
                  />
                ))
              ) : (
                <Typography variant="body2">No Features Avaliable</Typography>
              )}
            </Box>
          </Box>
          {isAdmin && (
              <>
                <Grid item xs={12} style={{ paddingTop: "5px" }}>
                  <Divider />
                </Grid>

                <Box>
                  <Typography variant="subtitle1" sx={{ mt: 1 }} fontWeight={500}>
                    Content URLs
                  </Typography>
                  <Box display="grid" gridTemplateColumns="40% 60%">
                    <Typography noWrap>{'Addressable URL'}</Typography>
                    <Typography sx={{ wordBreak: "break-word", overflowWrap: "break-word" }}>
                      {addressableURL ? addressableURL : '-'}
                    </Typography>
                    <Typography noWrap>{'Non Addressable URL'}</Typography>
                    <Typography sx={{ wordBreak: "break-word", overflowWrap: "break-word" }}>
                      {nonAddressableURL ? nonAddressableURL : '-'}
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
        </Grid>

        {windowWidth < 900 ? (
          <Grid item xs={12} style={{ paddingTop: "5px" }}>
            <Divider />
          </Grid>
        ) : null}

        <Grid item xs={12} md={6} sx={{ pt: 1.5 }}>
          <Typography variant="subtitle1" fontWeight={500}>
            Apps
          </Typography>
          <Box sx={{ mt: 1 }}>
            {sortedApps.map((app) => (
              <Chip
                key={app.id}
                label={app.appName}
                color={
                  appStates[app.id] === "enabled"
                    ? "success"
                    : appStates[app.id] === "locked"
                    ? "warning"
                    : "error"
                }
                sx={{ marginRight: 1, marginBottom: 1 }}
              />
            ))}
          </Box>
        </Grid>
      </Grid>

      <ConfigurationEditDialog
        open={isEditDialogOpen}
        handleClose={() => setIsEditDialogOpen(false)}
        configuration={configuration}
      />
      <ConfirmDeclineDialog
        open={isDeleteDialogOpen}
        dialogTitle="Delete Configuration"
        dialogText="Are you sure that you want to delete this configuration? This operation cannot be undone."
        confirmText="Confirm"
        declineText="Exit"
        handleClose={() => setIsDeleteDialogOpen(false)}
        handleConfirmAction={handleDelete}
        handleDeclineAction={() => setIsDeleteDialogOpen(false)}
      />
    </Paper>
  );
});

export default ConfigurationListItem;
