// License Types
export const LICENSE_TYPES_ARRAY = ["Lifetime", "Subscription", "Trial"];
export const LICENSE_TYPES = {
  LIFETIME: "Lifetime",
  SUBSCRIPTION: "Subscription",
  TRIAL: "Trial",
};

// License Statuses
export const LICENSE_STATUSES_ARRAY = [
  "Disabled",
  "Payment Required",
  "Validity Expired",
  "Validity Not Started",
];
export const LICENSE_STATUSES = {
  DISABLED: "Disabled",
  PAYMENT_REQUIRED: "Payment Required",
  VALIDITY_EXPIRED: "Validity Expired",
  VALIDITY_NOT_STARTED: "Validity Not Started",
};

// License Sort By Options
export const LICENSE_SORT_BY_ARRAY = [
  "Name",
  "Creation Date",
  "Update Date",
  "Valid From",
  "Valid Till",
];
export const LICENSE_SORT_BY = {
  NAME: "Name",
  CREATION_DATE: "Creation Date",
  UPDATE_DATE: "Update Date",
  VALID_FROM: "Valid From",
  VALID_TILL: "Valid Till",
};

//Payments Type
export const PAYMENT_TYPE_LIFETIME_ARR = ["Lifetime", "Lifetime Upgrade"];
export const PAYMENT_TYPE_SUBSCRIPTION_ARR = ["Subscription", "Subscription Upgrade", "Subscription Extension"];



export const PAYMENT_TYPES = {
  LIFETIME: "Lifetime",
  LIFETIME_UPGRADE: "Lifetime Upgrade",
  SUBSCRIPTION: "Subscription",
  SUBSCRIPTION_UPGRADE: "Subscription Upgrade",
  SUBSCRIPTION_EXTENSION: "Subscription Extension",
};



//Platforms Type
export const PLATFORMS_TYPE = [
  { value: "Windows", label: "Windows" },
  { value: "Android", label: "Android" },
]

//Emailer URL
export const EMAILER_URL = "https://europe-west1-brainssistance.cloudfunctions.net/sendEmail"


//Changelog types
export const CHANGELOG_TYPES = {
  UPDATE: "update",
  CREATE: "create",
  DELETE: "delete",
};

//User Roles
export const USER_ROLE = {
  ADMIN: "Admin",
  EDITOR: "Editor",
  VIEWER: "Viewer"
}

//Main Color
export const COLOR_PURPLE = "#403C8C"