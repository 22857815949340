import { Box, Drawer, IconButton, Typography } from "@mui/material";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PaymentsIcon from "@mui/icons-material/Payments";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { LICENSE_TYPES } from "../../../utils/helpers/constants";
import LinkOffIcon from "@mui/icons-material/LinkOff";

const ControllsDrawer = ({
  license,
  isAdmin,
  isDisabledChip,
  setIsPaymentHistoryOpen,
  setIsDisableDialogOpen,
  setIsEditDialogOpen,
  setIsDeleteDialogOpen,
  setIsChangeLogOpen,
  setIsUnlinkDialogOpen
}) => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box
      sx={{
        height: "100%",
        width: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px 0",
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <Box display="flex" flexDirection="column" gap="20px">
        <Box display="flex" alignItems="center" gap="10px">
          <IconButton
            sx={{
              backgroundColor: "#403C8C",
              color: "white",
              ":hover": { color: "#403C8C", backgroundColor: "white" },
            }}
            onClick={() => setIsChangeLogOpen(true)}
          >
            <ManageSearchIcon />
          </IconButton>
          <Typography
            sx={{ cursor: "pointer" }}
            onClick={() => setIsChangeLogOpen(true)}
          >
            Changelog
          </Typography>
        </Box>
        {license.type !== LICENSE_TYPES.TRIAL && (
          <Box display="flex" alignItems="center" gap="10px">
            <IconButton
              sx={{
                backgroundColor: "#403C8C",
                color: "white",
                ":hover": { color: "#403C8C", backgroundColor: "white" },
              }}
              onClick={() => setIsPaymentHistoryOpen(true)}
            >
              <PaymentsIcon />
            </IconButton>
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={() => setIsPaymentHistoryOpen(true)}
            >
              Payments
            </Typography>
          </Box>
        )}

        <Box display="flex" alignItems="center" gap="10px">
          <IconButton
            sx={{
              backgroundColor: "#403C8C",
              color: "white",
              ":hover": { color: "#403C8C", backgroundColor: "white" },
            }}
            onClick={() => setIsUnlinkDialogOpen(true)}
            disabled={license.hardwareIds.length === 0}
          >
            <LinkOffIcon />
          </IconButton>
          {license.hardwareIds.length === 0 ? <Typography
            sx={{ cursor: "pointer", color: "gray" }}
          >
            Unlink Device
          </Typography> :<Typography
            sx={{ cursor: "pointer" }}
            onClick={() => setIsUnlinkDialogOpen(true)}
          >
            Unlink Device
          </Typography>}
        </Box>

        {isAdmin && (
          <Box display="flex" alignItems="center" gap="10px">
            <IconButton
              sx={{
                backgroundColor: "#403C8C",
                color: "white",
                ":hover": { color: "#403C8C" },
              }}
              onClick={() => setIsDisableDialogOpen(true)}
            >
              {!isDisabledChip ? <StopCircleIcon /> : <PlayCircleIcon />}
            </IconButton>
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={() => setIsDisableDialogOpen(true)}
            >
              {!isDisabledChip ? "Disable" : "Enable"}
            </Typography>
          </Box>
        )}

        <Box display="flex" alignItems="center" gap="10px">
          <IconButton
            sx={{
              backgroundColor: "#403C8C",
              color: "white",
              ":hover": { color: "#403C8C" },
            }}
            onClick={() => setIsEditDialogOpen(true)}
          >
            <EditIcon />
          </IconButton>
          <Typography
            sx={{ cursor: "pointer" }}
            onClick={() => setIsEditDialogOpen(true)}
          >
            Edit
          </Typography>
        </Box>
        {isAdmin && (
          <Box display="flex" alignItems="center" gap="10px">
            <IconButton
              color="error"
              onClick={() => setIsDeleteDialogOpen(true)}
              disabled={!isAdmin}
            >
              <DeleteIcon />
            </IconButton>
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={() => setIsDeleteDialogOpen(true)}
            >
              Delete
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <div>
      <IconButton
        onClick={toggleDrawer(true)}
        sx={{
          borderRadius: "50%",
          backgroundColor: "#403C8C",
          color: "white",
          ":hover": { color: "#403C8C" },
        }}
      >
        <ExpandMore />
      </IconButton>
      <Drawer anchor="bottom" open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
};

export default ControllsDrawer;
