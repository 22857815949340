import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setLicenseListSearchString,
  setLicenseListSortBy,
  setLicenseListStatusSelect,
  setLicenseListTypeSelect,
  setLicenseOverviewGroupId,
} from "../../../store/license-overview/license-overview.action";
import {
  selectEnhancedUser,
  selectUserGroups,
} from "../../../store/user/user.selector";
import {
  selectLicenseOverviewGroup,
  selectLicenseOverviewGroupId,
} from "../../../store/license-overview/license-overview.selector";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { selectGroups } from "../../../store/groups/groups.selector";
import { ClearIcon } from "@mui/x-date-pickers";
import SearchIcon from "@mui/icons-material/Search";
import { LicenseFilterSelector } from "./license-filter-select.component";
import { Formik } from "formik";
import {
  LICENSE_SORT_BY,
  LICENSE_SORT_BY_ARRAY,
  LICENSE_STATUSES_ARRAY,
  LICENSE_TYPES_ARRAY,
  USER_ROLE,
} from "../../../utils/helpers/constants";

const LicenseOverviewGroupSelection = ({
  filteredLicenses,
  numberOfLicenses,
}) => {
  const [query, setQuery] = useState("");
  const [sortBy, setSortBy] = useState(LICENSE_SORT_BY.NAME);

  const dispatch = useDispatch();
  const currentGroupId = useSelector(selectLicenseOverviewGroupId);
  const userGroups = useSelector(selectUserGroups);
  const avalibleGroup = useSelector(selectLicenseOverviewGroup).sort((a, b) =>
    a.groupName.localeCompare(b.groupName)
  );
  const currentUser = useSelector(selectEnhancedUser);
  const allGroups = useSelector(selectGroups);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(setLicenseListSearchString(query));
  }, [query]);

  useEffect(() => {
    dispatch(setLicenseListSortBy(sortBy));
  }, [sortBy]);

  const isAdmin = currentUser.role === USER_ROLE.ADMIN;

  useEffect(() => {
    if (isAdmin && allGroups.length > 0) {
      dispatch(setLicenseOverviewGroupId(allGroups[0].id));
    }

    if (userGroups && userGroups.length > 0 && !isAdmin) {
      dispatch(setLicenseOverviewGroupId(userGroups[0].id));
    }
  }, [userGroups, allGroups]);

  const handleChange = (event) => {
    dispatch(setLicenseOverviewGroupId(event.target.value));
  };

  const handleChangeType = (type) => {
    dispatch(setLicenseListTypeSelect(type));
  };

  const handleChangeStatus = (status) => {
    dispatch(setLicenseListStatusSelect(status));
  };

  const handleChangeSort = (event) => {
    setSortBy(event.target.value);
  };

  return (
    <Formik
      initialValues={{
        licenseTypes: [],
        licenseStatuses: [],
      }}
    >
      <Fragment>
        <Box display="flex" flexDirection="column" gap="20px">
          {((userGroups && userGroups.length > 1) ||
            (isAdmin && allGroups.length > 1)) && (
            <FormControl>
              <InputLabel id="license-overview-select-label">Group</InputLabel>
              <Select
                labelId="license-overview-select-label"
                label="Group"
                fullWidth={true}
                value={currentGroupId}
                onChange={handleChange}
              >
                {avalibleGroup.map(({ id, groupName }, idx) => (
                  <MenuItem key={idx} value={id}>
                    {groupName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <TextField
            variant="outlined"
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {query !== "" && (
                    <IconButton onClick={() => setQuery("")}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Box
            display="flex"
            flexDirection={windowWidth < 500 ? "column" : "row"}
            gap="10px"
            justifyContent="space-between"
          >
            <Box
              sx={{ margin: 0, p: 0 }}
              width={windowWidth < 500 ? "100%" : "80%"}
              display="flex"
              gap="10px"
              flexDirection={windowWidth < 500 ? "column" : "row"}
              marginY={1}
            >
              <LicenseFilterSelector
                avalibleOptions={LICENSE_TYPES_ARRAY}
                filterName="Type"
                setFilterOptions={handleChangeType}
                fieldName="licenseTypes"
                multiple={true}
              />
              <LicenseFilterSelector
                avalibleOptions={LICENSE_STATUSES_ARRAY}
                filterName="Status"
                setFilterOptions={handleChangeStatus}
                fieldName="licenseStatuses"
                multiple={true}
              />
            </Box>
            <Box
              sx={{ margin: 0, p: 0 }}
              width={windowWidth < 500 ? "100%" : "20%"}
              marginY={1}
            >
              <FormControl fullWidth={true}>
                <InputLabel id="license-overview-sort-label">
                  Sort By
                </InputLabel>
                <Select
                  labelId="license-overview-sort-label"
                  label="Sort By"
                  value={sortBy}
                  onChange={handleChangeSort}
                >
                  {LICENSE_SORT_BY_ARRAY.map((groupName, index) => (
                    <MenuItem key={index} value={groupName}>
                      {groupName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          position="relative"
          justifyContent="flex-end"
          sx={{ m: 0, p: 0 }}
        >
          <Typography color="grey" position="absolute" right="0" bottom="0">
            {filteredLicenses.length === 0 ? null : numberOfLicenses}
          </Typography>
        </Box>
      </Fragment>
    </Formik>
  );
};

export default LicenseOverviewGroupSelection;
