import { APPS_ACTION_TYPES } from "./apps.types";

const INITIAL_STATE = {
    apps: [],
    searchString: "",
}

export const appsReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch(type) {
        case APPS_ACTION_TYPES.SET_APPS:
            return { ...state, apps : payload };

        case APPS_ACTION_TYPES.SET_APP_LIST_SEARCH_STRING:
        {                
            return { ...state, searchString: payload };
        }

        default:
            return state;
    }
}