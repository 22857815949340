import { useField, useFormikContext } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { FormControl } from "@mui/material";

const FormikAutocomplete = ({ name, label, options, handleGroups = () => {}, multiple, ...otherProps }) => {
  const [field, meta, helpers] = useField(name);
  const { values, setValues } = useFormikContext();

  const handleChange = (event, newValue) => {
    setValues({ ...values, [name]: newValue });
    handleGroups(newValue);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
  };

  const sorted = [...options].sort((a, b) => a.localeCompare(b));

  const isOptionEqualToValue = (option, value) => option === value;

  const configFormControl = {
    fullWidth: true,
    error: meta.touched && !!meta.error,
  };

  return (
    <FormControl {...configFormControl} variant="outlined">
      <Autocomplete
        {...otherProps}
        name={field.name}
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        value={values[name]}
        options={sorted}
        isOptionEqualToValue={isOptionEqualToValue}
        multiple={multiple || false}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            name={field.name}
            variant="outlined"
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error ? meta.error : ""}
          />
        )}
      />
    </FormControl>
  );
};

export default FormikAutocomplete;
