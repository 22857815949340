import List from "@mui/material/List";
import LicenseOverviewListItem from "../../components/license-list/license-list-item.component";

const LicenseList = ({ overviewLicenses }) => {
  return (
    <List>
      {overviewLicenses.map((license, idx) => (
        <LicenseOverviewListItem key={idx} license={license} />
      ))}
    </List>
  );
};

export default LicenseList;
