import { Container, Stack, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react'
import FloatingActionButton from '../../components/floating-action-button/floating-action-button.component';
import AddIcon from '@mui/icons-material/Add';
import FeaturesList from '../../components/features/features-list.component';
import FeaturesAddDialog from '../../components/features/features-add-dialog.component';
import FeaturesListSearchBar from '../../components/features/features-search-bar.component';

const Features = () => {
    const [isFeaturesDialogOpen, setIsFeaturesDialogOpen] = useState(false);    

    const openFeaturesDialog = () => {
        setIsFeaturesDialogOpen(true);
    }
    
    const closeFeaturesDialog = () => {
        setIsFeaturesDialogOpen(false);
    }  

    return (
        <Fragment>
            <Container>
                <Stack spacing={4} marginTop={4} marginBottom={8}>
                    <Typography variant="h5" textAlign="center">
                        Features
                    </Typography>
                    <FeaturesListSearchBar />
                    <FeaturesList />
                </Stack>  
            </Container> 
            <FeaturesAddDialog
                open={isFeaturesDialogOpen}
                handleClose={closeFeaturesDialog}
            /> 
            <FloatingActionButton handleClick={openFeaturesDialog}>
                <AddIcon />
            </FloatingActionButton>
        </Fragment>
    );
}

export default Features;