import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import Button from '@mui/material/Button';

const NextButton = ({ onClick, isNextButtonDisabled, appStateEndable, activeStep }) => {
  const { validateForm, isValid } = useFormikContext();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const checkIfDisabled = async () => {
      await validateForm();
      setDisabled(!isValid);
    };

    checkIfDisabled();
  }, [validateForm, isValid]);

  return (
    <Button
      onClick={onClick}
      disabled={disabled || isNextButtonDisabled || (activeStep === 1 && appStateEndable)}
      sx={{ color: '#403C8C' }}
    >
      Next
    </Button>
  );
};

export default NextButton;
