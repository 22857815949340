import { LICENSE_OVERVIEW_ACTION_TYPES } from "./license-overview.types";

const INITIAL_STATE = {
  groupId: "",
  searchString: "",
  types: [],
  statuses: [],
  sortBy: ""
};

export const licenseOverviewReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case LICENSE_OVERVIEW_ACTION_TYPES.SET_GROUP_ID:
      return { ...state, groupId: payload };

    case LICENSE_OVERVIEW_ACTION_TYPES.SET_LICENSE_LIST_SEARCH_STRING: {
      return { ...state, searchString: payload };
    }

    case LICENSE_OVERVIEW_ACTION_TYPES.SET_LICENSE_LIST_TYPE_SELECT: {
      return { ...state, types:  payload };
    }

    case LICENSE_OVERVIEW_ACTION_TYPES.SET_LICENSE_LIST_STATUS_SELECT: {
      return { ...state, statuses: payload };
    }

    case LICENSE_OVERVIEW_ACTION_TYPES.SET_LICENSE_LIST_SORT_BY: {
      return { ...state, sortBy: payload };
    }

    default:
      return state;
  }
};