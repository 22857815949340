import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { selectLanguages } from "../../store/languages/languages.selector";

import { addEntry } from "../../utils/firebase/firebase-firestore.utils";
import { FIREBASE_COLLECTION_NAMES } from "../../utils/firebase/firebase-firestore.utils";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import FormikButton from "../formik-button/formik-button.component";
import FormikTextfield from "../formik-textfield/formik-textfield.component";
import { selectUsers } from "../../store/users/users.selector";
import { formatTimestamp } from "../../utils/helpers/helpers";
import { getAuth } from "firebase/auth";
import axios from "axios";
import { EMAILER_URL, USER_ROLE } from "../../utils/helpers/constants";
import { generateUID } from "../../utils/uid";

const LanguageAddDialog = ({ open, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const allLanguages = useSelector(selectLanguages);

  //Admin users
  const users = useSelector(selectUsers);
  const adminEmails = users
    .filter((user) => user.role === USER_ROLE.ADMIN && user.enableNotification === true)
    .map((admin) => admin.notificationEmail);

  //Submit
  const handleSubmit = async (values, { resetForm }) => {
    const { languageCode, languageName } = values;
    const auth = getAuth();
    const id = generateUID();

    // Close the dialog immediately
    handleClose();

    try {
      addEntry(FIREBASE_COLLECTION_NAMES.LANGUAGES, id, {
        id,
        languageCode,
        languageName,
      });

      enqueueSnackbar(`Language "${languageName}" was successfully added.`, {
        variant: "success",
      });

      // Emailer start
      const formattedDate = formatTimestamp({
        seconds: new Date().getTime() / 1000,
        nanoseconds: 0,
      });
      const subject = `"${languageName}" Language Added`;
      const text =
        `The language "${languageName}" got added by ${auth.currentUser.email} at ${formattedDate}.\n \n` +
        `Language Name: ${languageName} \n` +
        `Language Code: ${languageCode} \n`;
      const recipients = [...adminEmails];

      const emailData = {
        to: recipients,
        subject,
        text,
      };

      axios
        .post(EMAILER_URL, emailData)
        .then((response) => {
          console.log("Email sent successfully");
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
      //Emailer end

      resetForm();
    } catch (error) {
      enqueueSnackbar(
        `Failed to add the language "${languageName}". Please try again.`,
        { variant: "error" }
      );
      console.log("adding language failed: ", error);
    }
  };

  const FORM_VALIDATION = Yup.object().shape({
    languageCode: Yup.string()
      .test(
        "is-language-code-unique",
        "Language Code is already used",
        (value) => {
          return (
            allLanguages.filter((language) => {
              return language.languageCode === value;
            }).length === 0
          );
        }
      )
      .required("Required"),
    languageName: Yup.string()
      .test("is-language-name-unique", "Already used", (value) => {
        return (
          allLanguages.filter((language) => {
            return language.languageName === value;
          }).length === 0
        );
      })
      .required("Required"),
  });

  return (
    <Formik
      initialValues={{ languageCode: "", languageName: "" }}
      validationSchema={FORM_VALIDATION}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      <Form>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
          <DialogTitle>Add New Language</DialogTitle>
          <DialogContent>
            <Stack
              direction="column"
              spacing={2}
              marginY={2}
              alignItems="start"
            >
              <FormikTextfield
                name="languageName"
                fullWidth={true}
                label="Language Name"
                variant="standard"
              />
              <FormikTextfield
                name="languageCode"
                fullWidth={true}
                label="Language Code"
                variant="standard"
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button sx={{ color: "red" }} onClick={handleClose}>
              Cancel
            </Button>
            <FormikButton type="submit" sx={{ color: "green" }}>
              Save
            </FormikButton>
          </DialogActions>
        </Dialog>
      </Form>
    </Formik>
  );
};

export default LanguageAddDialog;
