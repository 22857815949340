import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuthStatus } from "../../store/user/user.selector";
import { USER_AUTH_STATES } from "../../store/user/user.types";

import Grid from "@mui/material/Grid";
import { ResetPasswordForm } from "./reset-pass.component";
import { SignInForm } from "./sign-in.component";

const Authentication = () => {
  const authStatus = useSelector(selectAuthStatus);
  const navigate = useNavigate();
  const [refresherOpen, setRefresherOpen] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (authStatus === USER_AUTH_STATES.SIGNED_IN_FINISHED) {
      navigate("/user/licenses");
    }
  }, [authStatus, navigate]);

  if (authStatus !== USER_AUTH_STATES.SIGNED_OUT) {
    return null;
  }

  return (
    <Grid
      container
      paddingY={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ flexGrow: 1 }}
    >
      {refresherOpen ? (
        <ResetPasswordForm setRefresherOpen={setRefresherOpen} email={email} />
      ) : (
        <SignInForm setRefresherOpen={setRefresherOpen} setEmail={setEmail} />
      )}
    </Grid>
  );
};

export default Authentication;
