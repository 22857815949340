import { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { selectConfigurations } from "../../store/configurations/configurations.selector";

import List from "@mui/material/List";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AddIcon from "@mui/icons-material/Add";

import ConfigurationListItem from "../../components/configuration-list/configuration-list-item.component";
import ConfigurationAddDialog from "../../components/configuration-list/configuration-add-dialog.component";
import FloatingActionButton from "../../components/floating-action-button/floating-action-button.component";
import React from "react";
import { selectEnhancedUser } from "../../store/user/user.selector";
import ConfigurationSearchBar from "../../components/configuration-list/configuration-search-bar";
import { selectGroups } from "../../store/groups/groups.selector";
import useDebounce, { alphanumericSort } from "../../utils/helpers/helpers";
import {
  warningConfigurationNoConfig,
  warningConfigurationNoConfigUser,
  warningConfigurationNoGroup,
  warningConfigurationNoResults,
} from "../../utils/helpers/warnings";
import { USER_ROLE } from "../../utils/helpers/constants";

const Configurations = () => {
  const configurations = useSelector(selectConfigurations);
  const groups = useSelector(selectGroups);
  const user = useSelector(selectEnhancedUser);

  const isAdmin = user?.role === USER_ROLE.ADMIN || false;

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 300);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const userGroups = groups
    .filter((group) => group.userEmails.includes(user.email))
    .map((group) => group.groupName);
  useEffect(() => {
    if (isAdmin) {
      setSelectedGroups(groups.map((group) => group.groupName));
    } else {
      setSelectedGroups(userGroups);
    }
  }, [groups, isAdmin, user.email]);

  const openAddDialog = () => {
    if (isAdmin) setIsAddDialogOpen(true);
  };

  const closeAddDialog = () => {
    setIsAddDialogOpen(false);
  };

  const handleSearch = (value) => {
    setQuery(value);
  };

  const handleGroups = (value) => {
    setSelectedGroups(value);
  };

  const filteredConfig = useMemo(() => {
    return configurations
      .filter((item) => {
        const nameMatches = item.configurationName
          .toLowerCase()
          .includes(debouncedQuery.toLowerCase());
        const groupMatches = selectedGroups.some((selectedGroup) => {
          const group = groups.find(
            (group) => group.groupName === selectedGroup
          );
          return group && group.id === item.groupId;
        });
        return nameMatches && groupMatches;
      })
      .sort((a, b) => alphanumericSort(a, b, "configurationName"));
  }, [configurations, debouncedQuery, selectedGroups, groups]);

  const warning = useMemo(() => {
    if (groups.length === 0) return null;

    const userGroups = groups.filter((group) =>
      group.userEmails.includes(user.email)
    );

    if (isAdmin && configurations.length === 0) {
      return warningConfigurationNoConfig;
    } else if (
      !isAdmin &&
      userGroups.length > 0 &&
      configurations.length === 0
    ) {
      return warningConfigurationNoConfigUser;
    } else if (!isAdmin && userGroups.length === 0) {
      return warningConfigurationNoGroup;
    } else if (filteredConfig.length === 0) {
      return warningConfigurationNoResults;
    }
    return null;
  }, [
    groups,
    isAdmin,
    configurations.length,
    filteredConfig.length,
    user.email,
  ]);

  return (
    <Fragment>
      <Container>
        <Stack spacing={4} marginTop={4} marginBottom={8}>
          <Typography variant="h5" textAlign="center">
            Configurations
          </Typography>
          <ConfigurationSearchBar
            handleSearch={handleSearch}
            query={query}
            groups={selectedGroups}
            allGroups={
              isAdmin ? groups.map((group) => group.groupName) : userGroups
            }
            handleGroups={handleGroups}
          />
          <List>
            {warning ? (
              <Typography textAlign="center">{warning}</Typography>
            ) : (
              filteredConfig.map((configuration, idx) => (
                <ConfigurationListItem
                  key={idx}
                  configuration={configuration}
                />
              ))
            )}
          </List>
        </Stack>
      </Container>
      {isAdmin && (
        <FloatingActionButton handleClick={openAddDialog}>
          <AddIcon />
        </FloatingActionButton>
      )}
      <ConfigurationAddDialog
        open={isAddDialogOpen}
        handleClose={closeAddDialog}
      />
    </Fragment>
  );
};

export default Configurations;
