import React from "react";
import { Box, Chip, Typography } from "@mui/material";

const AddFeaturesAndLanguages = ({
  featureInitState,
  toggleFeature,
  features,
  languages,
  toggleLang,
  langInitState,
}) => {
  
  return (
    <>
      <Box sx={{ mt: 1 }}>
          {features.length > 0 ? (
            features.map((featureName) => (
              <Chip
                onClick={() => toggleFeature(featureName)}
                key={featureName}
                label={featureName}
                color={
                  featureInitState && featureInitState.includes(featureName)
                    ? "success"
                    : "error"
                }
                sx={{ marginRight: 1, marginBottom: 1 }}
              />
            ))
          ) : (
            <Typography variant="body2">No Features Available</Typography>
          )}
        </Box>
      <Box>
        <Typography variant="subtitle1" sx={{ mt: 1 }} fontWeight={500}>
          Languages
        </Typography>
        <Box sx={{ mt: 1 }}>
          {languages.length > 0 ? (
            languages.map((languageName) => (
              <Chip
                onClick={() => toggleLang(languageName)}
                key={languageName}
                label={languageName}
                color={
                  langInitState && langInitState.includes(languageName)
                    ? "success"
                    : "error"
                }
                sx={{ marginRight: 1, marginBottom: 1 }}
              />
            ))
          ) : (
            <Typography variant="body2">No Languages Available</Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AddFeaturesAndLanguages;
