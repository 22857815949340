import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectEnhancedUser } from "../../store/user/user.selector";

import LoadingToRedirect from '../../components/loading/loading-to-redirect.component';
import { USER_ROLE } from "../helpers/constants";

const AuthRouteAdmin = () => {
    const currentUser = useSelector(selectEnhancedUser);

    return currentUser && currentUser.role === USER_ROLE.ADMIN? <Outlet/> : <LoadingToRedirect redirectTo="/user/licenses" />;
};

export default AuthRouteAdmin;